const {
  colors, spacing, screens, fontSize,
} = require('tailwindcss/defaultTheme');

module.exports = {
  purge: {
    content: [
      // './src/**/*.html',
      // './src/**/*.js',
      // './src/**/*.vue',
      './src/**/*.{js,jsx,ts,tsx,vue,html}',
    ],
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      fontFamily: {
        sans: ['Source Sans Pro', 'sans-serif'],
        code: ['Source Code Pro', 'sans-serif'],
        satisfy: ['Satisfy', 'serif'],
      },
      fontSize: {
        ...fontSize,
        xxxs: '0.5625rem',
        xxs: '0.625rem',
      },
      minWidth: {
        ...spacing,
        xxxs: '12rem',
        xxs: '16rem',
        xs: '20rem',
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
      },
      minHeight: {
        ...spacing,
        xxxs: '12rem',
        xxs: '16rem',
        xs: '20rem',
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
      },
      colors: {
        ...colors,
        collection: {
          kobi: '#DB9DBC',
          'coral-red': '#FA4339',
          'space-cadet': '#1C2F56',
          'brunswick-green': '#16544E',
          // amaranth: '#E02F55',
          ochre: '#DC781B',
          'true-blue': '#0176CB',
          'light-carmine-pink': '#E56674',
          'sizzling-red': '#F73558',
        },
        secondary: {
          red: '#F73558',
          green: '#63D374',
          yellow: '#FFC800',
        },
        primary: {
          red: '#F9423A',
        },
      },
      screens: {
        xs: '375px',
        ...screens,
      },
    },
  },
  variants: {
    extend: {
      // textColor: ['disabled'],
      backgroundColor: ['active'],
    },
    fill: ['hover', 'focus'],
    textColor: ['responsive', 'hover', 'focus', 'group-hover'],
    scale: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    opacity: ['disabled'],
  },
  plugins: [],
};
