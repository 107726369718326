<template>
  <gc-page-template>
    <div class="min-h-screen flex py-32">
      <div
        class="flex flex-col items-center flex-grow"
        >
        <gc-section-heading
          :pre-title="$t('pages.privacyPolicy.pretitle')"
          :title="$t('pages.privacyPolicy.title')"
          class="pb-12"
          color="text-white"
          />
        <div class="container flex flex-col justify-center items-center mx-auto">
          PrivacyPolicy
        </div>
      </div>
    </div>
  </gc-page-template>
</template>

<script>
import gcSectionHeading from '@/components/SectionHeading.vue';
import gcPageTemplate from '@/components/PageTemplate.vue';

export default {
  components: {
    gcSectionHeading,
    gcPageTemplate,
  },
};
</script>

<style>

</style>
