<template>
  <gc-notification
    v-if="!!Object.keys(notificationToDisplay).length"
    :is-open="!!Object.keys(notificationToDisplay).length"
    :payload="notificationToDisplay"
    @close="dismiss"
    />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import gcNotification from '@/components/Notification.vue';

export default {
  components: {
    gcNotification,
  },
  data() {
    return {
      notificationToDisplay: {},
      isDismissed: false,
      elapsedUntilPaused: 0,
      isRunning: false,
    };
  },
  computed: {
    ...mapState({
      notificationQueue: state => state.common.notificationQueue,
    }),
  },
  watch: {
    notificationQueue(payload) {
      if (
        payload.length !== 0
        && !Object.keys(this.notificationToDisplay).length
      ) {
        // this.$log.info('Watcher will assign the notification');
        [this.notificationToDisplay] = payload;
      }
    },
  },
  methods: {
    ...mapMutations({
      spliceFromNotificationQueue: 'common/spliceFromNotificationQueue',
    }),
    // beforeEnter() {
    //   this.$log.info('beforeEnter', this.elapsedUntilPaused);

    //   this.isDismissed = false;
    // },
    // afterEnter() {
    //   this.$log.info('afterEnter');
    // },
    // afterLeave() {
    //   this.$log.info('afterLeave');
    //   this.spliceFromNotificationQueue(0);
    // },
    dismiss() {
      this.$log.warn(
        'Dismissing notification with id',
        this.notificationToDisplay,
        this.notificationToDisplay.body,
      );

      this.notificationToDisplay = {};
      this.spliceFromNotificationQueue(0);
      // this.elapsedUntilPaused = 0;
      // this.isDismissed = true;
      // this.isRunning = false;
      // this.spliceFromNotificationQueue(0);
      // this.$root.$emit('notificationState', this.notificationState);
    },
  },
};
</script>

<style>
</style>
