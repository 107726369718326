<template>
  <div class="flex flex-1 flex-col transition-set items-center sm:items-start">
    <div class="bg-black rounded-full bg-opacity-20 mb-4">
      <gc-svg-base
        color-class="fill-current text-white"
        svg="icons/icon-opensea-big"
        />
    </div>

    <gc-heading
      tag="h1"
      color="text-white"
      class="uppercase font-bold pb-2 sm:text-left text-center text-3xl sm:text-4xl"
      >
      {{ $t('pages.landing.postMintingTitle') }}
    </gc-heading>

    <gc-text
      variant="body-code-sm"
      color="text-white"
      class="sm:text-left text-center pb-6 whitespace-pre-line"
      >
      {{ $t('pages.landing.postMintingBody') }}
    </gc-text>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
