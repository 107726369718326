<template>
  <gc-io-observer
    id="landing"
    class="min-h-screen flex py-32"
    :class="getSectionStyle.background"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="container flex flex-col justify-center items-center mx-auto px-4"
      >
      <div
        v-if="true"
        class="flex flex-col sm:flex-row w-full items-end flex-grow"
        >
        <!-- Content -->
        <gc-pre-minting v-if="getCurrentPhase === 'pre-minting'" />

        <gc-minting-now v-if="getCurrentPhase === 'minting-now'" />

        <gc-allowlist-minting v-if="getCurrentPhase === 'allowlist-minting'" />

        <gc-post-minting v-if="getCurrentPhase === 'post-minting'" />

        <!-- Gentlemen image -->
        <gc-landing-carousel
          class="w-full flex flex-col items-center place-self-end flex-1 justify-end"
          />


        <!-- Gentleman name -->
        <div
          v-if="true"
          class="hidden lg:flex flex-1 justify-end"
          >
          <div class="flex items-center pb-6">
            <gc-landing-name-carousel
              v-if="true"
              :name="name"
              />
            <gc-svg-base
              color-class="ml-4 fill-current text-white"
              svg="icons/icon-left-arrow"
              />
          </div>
        </div>
      </div>

      <!-- Stroke -->
      <div
        v-if="true"
        class="bg-black opacity-20 h-2 w-full max-w-4xl min-w-xs rounded-full"
        />

      <!-- Actions -->
      <gc-pre-minting-actions v-if="getCurrentPhase === 'pre-minting'" />

      <gc-allowlist-minting-actions v-else-if="getCurrentPhase === 'allowlist-minting'" />

      <gc-minting-now-actions v-else-if="getCurrentPhase === 'minting-now'" />

      <gc-post-minting-actions v-else-if="getCurrentPhase === 'post-minting'" />
    </div>
  </gc-io-observer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import gcIoObserver from '@/components/IoObserver.vue';

import gcPreMinting from '@/components/PreMinting.vue';
import gcAllowlistMinting from '@/components/AllowlistMinting.vue';
import gcMintingNow from '@/components/MintingNow.vue';
import gcPostMinting from '@/components/PostMinting.vue';

import gcPreMintingActions from '@/components/PreMintingActions.vue';
import gcAllowlistMintingActions from '@/components/AllowlistMintingActions.vue';
import gcMintingNowActions from '@/components/MintingNowActions.vue';
import gcPostMintingActions from '@/components/PostMintingActions.vue';

import gcLandingCarousel from '@/components/LandingCarousel.vue';
import gcLandingNameCarousel from '@/components/LandingNameCarousel.vue';

export default {
  components: {
    gcIoObserver,
    gcPreMinting,
    gcAllowlistMinting,
    gcMintingNow,
    gcPostMinting,
    gcPreMintingActions,
    gcAllowlistMintingActions,
    gcMintingNowActions,
    gcPostMintingActions,
    gcLandingCarousel,
    gcLandingNameCarousel,
  },
  data() {
    return {
      preMintingFinished: false,
      minMintAmount: 1,
      maxMintAmount: 10,
      mintAmount: 1,
      fade: false,
      name: '',
    };
  },
  computed: {
    ...mapState({
      notificationQueue: state => state.common.notificationQueue,
      blockchain: state => state.blockchain,
    }),
    ...mapGetters({
      isConnected: 'blockchain/isConnected',
      getNFTCost: 'blockchain/getNFTCost',
      isMintingFinished: 'blockchain/isMintingFinished',
      getCurrentPhase: 'blockchain/getCurrentPhase',
      getSectionStyle: 'common/getSectionStyle',
    }),
  },
  created() {
    /**
     * Start fresh to prevent any illegal state
     * @todo check if this works as expected when multiple wallets available in the browser
     * @todo On Brave there is a reload when loading the url for the first time
     * https://github.com/MetaMask/providers/issues/110
     */
    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        this.$log.info('Landing: Chain changed');
        window.location.reload();
      });
    }

    this.$root.$on('current-slide', (event) => {
      this.name = event.name;
    });
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
  },
};
</script>

<style>
</style>
