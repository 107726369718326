import Vue from 'vue';
// import Web3 from 'web3';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';

// import Contract from 'web3-eth-contract';
import * as Sentry from '@sentry/vue';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import i18n from '@/i18n';
import { isEmptyObject } from '@/mixins/utils';
import DappService from '@/services/DappService';

import allowlistMainnet from '../../../public/allowlist/allowlistMainnet.json';
import allowlistRinkeby from '../../../public/allowlist/allowlistRinkeby.json';

const initialState = {
  loading: false,
  account: null,
  config: {},
  smartContract: null,
  web3: null,
  claimingNFT: false,
  balance: 0,
  totalSupply: 0,
  isPaused: true,
  currentPhase: 'pre-minting',
};

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// State object
const state = {
  ...initialState,
};

// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  setInitialState() {
    Object.assign(state, initialState);
  },
};

// Actions
const actions = {
  async fetchAbi() {
    return DappService.getAbi();
  },

  async fetchAccumulatedHashString() {
    return DappService.getAccumulatedHashString();
  },

  async fetchProvenance() {
    return DappService.getProvenance();
  },

  async fetchConfig({ commit }) {
    try {
      const config = {
        CONTRACT_ADDRESS: '0xb1952467272AAd976E49b49ea53bE855aA1481e8',
        SCAN_LINK: 'https://etherscan.io/token/0xb1952467272AAd976E49b49ea53bE855aA1481e8',
        NETWORK: {
          NAME: 'Ethereum',
          SYMBOL: 'ETH',
          ID: 1,
        },
        NFT_NAME: 'Gentlemen Club',
        SYMBOL: 'GNTLMN',
        MAX_SUPPLY: 10000,
        MAX_MINT_AMOUNT_PER_TX: 10,
        MAX_ALLOWLIST_MINT_AMOUNT_PER_TX: 3,
        WEI_COST: 70000000000000000,
        GAS_LIMIT: 285000,
        PRE_MINTING_END_TIME: 'Mar 9, 2022 15:00:00',
        IS_ALLOWLIST_MINTING_FINISHED: true,
        IS_MINTING_FINISHED: false,
        MARKETPLACE: 'Opeansea',
        MARKETPLACE_LINK: 'https://opensea.io/collection/the-gentlemen-club-collection',
      };

      // const response = await DappService.getConfig();
      // Vue.$log.info('Store: fetchConfig', response);
      commit('setState', ['config', config]);

      return config;
    } catch (error) {
      Sentry.captureException(error);
      throw new Error('Unable to fetch dapp config');
    }
  },

  async getSmartContract(ctx) {
    const { config } = ctx.state;
    const abi = await ctx.dispatch('fetchAbi');

    try {
      await ctx.dispatch('makeChainCheck');

      const web3 = ctx.state.web3();

      const smartContract = new web3.eth.Contract(abi.abi, config.CONTRACT_ADDRESS);

      ctx.commit('setState', ['smartContract', () => smartContract]);

      const cost = await smartContract.methods.cost().call();
      Vue.$log.info('getSmartContract success:', cost);
    } catch (error) {
      Vue.$log.info('getSmartContract error:', error);
      Sentry.captureException(error);
    }
  },

  async makeChainCheck(ctx) {
    const provider = ctx.getters.getProvider;
    // Fetch user config for the Dapp
    let { config } = ctx.state;

    if (isEmptyObject(ctx.state.config)) {
      config = await ctx.dispatch('fetchConfig');
    }

    // Always prefer the chain ID over the network ID.
    // https://docs.metamask.io/guide/ethereum-provider.html#ethereum-networkversion-deprecated
    const networkId = await provider?.request({ method: 'net_version' });

    const chainId = await provider?.request({ method: 'eth_chainId' });

    // Prefer to use chainId, when necessary fallback to networkId
    const chainIdNumber = parseInt(chainId?.split('x')[1], 10) || parseInt(networkId, 10);

    // Return if user is NOT on the correct chain/network
    if (chainIdNumber !== config.NETWORK.ID) {
      const errorMessage = i18n.t('feedbacks.wrongNetwork');
      // Vue.$log.info('makeChainCheck: errorMessage', errorMessage);
      // Vue.$log.info('chainId:', chainIdNumber);
      throw new Error(errorMessage);
    }

    return true;
  },

  makeMetaMaskCheck() {
    const metaMaskIsInstalled = window.ethereum && window.ethereum.isMetaMask;

    if (!metaMaskIsInstalled) {
      Vue.$log.info('Will throw Install MetaMask');
      throw new Error('Install MetaMask');
    }
    return true;
  },

  async getTotalSupply({ state, commit, dispatch }) {
    try {
      await dispatch('makeChainCheck');

      const totalSupply = (await state.smartContract?.().methods.totalSupply().call()) || state.totalSupply;

      Vue.$log.info('Store: getTotalSupply', totalSupply);

      commit('setState', ['totalSupply', totalSupply]);

      return totalSupply;
    } catch (error) {
      Sentry.captureException(error);

      Vue.$log.info('getTotalSupply error:', error.message);
      throw new Error(i18n.t('feedbacks.couldNotLoadContract'), { cause: error });
    }
  },

  initWeb3(ctx) {
    try {
      // ctx.dispatch('makeMetaMaskCheck');

      const web3 = createAlchemyWeb3(process.env.VUE_APP_ALCHEMY_KEY);
      web3.eth.handleRevert = true;

      ctx.commit('setState', ['web3', () => web3]);
    } catch (error) {
      Vue.$log.info('initWeb3', error);
    }
  },

  async connect(ctx) {
    // Get ethereum attached by MetaMask
    const provider = ctx.getters.getProvider;

    // Fetch contract abi for the dapp
    const abi = await ctx.dispatch('fetchAbi');
    Vue.$log.info('Abi acquired', abi);

    // Fetch user config for the Dapp
    let { config } = ctx.state;

    if (isEmptyObject(ctx.state.config)) {
      config = await ctx.dispatch('fetchConfig');
    }

    // This property is non-standard. Non-MetaMask providers may also set this property to true.
    // https://docs.metamask.io/guide/ethereum-provider.html#ethereum-ismetamask

    try {
      ctx.dispatch('makeMetaMaskCheck');

      // Check if user is on the correct chain, if not throws error
      await ctx.dispatch('makeChainCheck');

      // Request ethereum accounts
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      Vue.$log.info('accounts', accounts);

      const web3 = ctx.state.web3();

      const smartContract = new web3.eth.Contract(abi.abi, config.CONTRACT_ADDRESS);

      // Store account, smart contract and web3 instances in state
      ctx.dispatch('connectSuccess', {
        account: accounts[0],
        smartContract: () => smartContract,
      });

      // Get balance for debugging purposes
      await ctx.dispatch('getBalance', accounts[0]);
      await ctx.dispatch('getTotalSupply');

      // Provide feedback to user that the wallet was connected
      ctx.dispatch(
        'common/pushNotification',
        {
          title: i18n.t('feedbacks.walletTitle'),
          body: i18n.t('feedbacks.walletConnected'),
          duration: 3000,
          payload: {
            type: 'success',
          },
        },
        { root: true },
      );

      // Keep account in state up to date
      provider.on('accountsChanged', accnts => ctx.dispatch('handleAccountsChanged', accnts));
    } catch (error) {
      /**
       * https://github.com/MetaMask/eth-rpc-errors/blob/main/src/error-constants.ts
       * https://eips.ethereum.org/EIPS/eip-1193#provider-errors
       * https://eips.ethereum.org/EIPS/eip-1474#error-codes
       *
       */
      Sentry.withScope((scope) => {
        Object.keys(error).forEach((key) => {
          scope.setExtra(key, error[key]);
        });
        Sentry.captureMessage(error.message, 'error');
      });

      switch (true) {
        case error.code === 4001:
          ctx.dispatch(
            'common/pushNotification',
            {
              title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.metaMaskNotConnected'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.metaMaskNotConnected'), { cause: error });
        case error.code === 4900:
          ctx.dispatch(
            'common/pushNotification',
            {
              title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.walletDisconnected'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.walletDisconnected'), { cause: error });
        case error.code === 4901:
          ctx.dispatch(
            'common/pushNotification',
            {
              title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.chainDisconnected'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.chainDisconnected'), { cause: error });
        case error.code === -32002:
          ctx.dispatch(
            'common/pushNotification',
            {
              title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.pendingWalletConnectionRequest'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.chainDisconnected'), { cause: error });
        case error.message === 'Install MetaMask':
          ctx.dispatch(
            'common/pushNotification',
            {
              title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.metaMaskNotInstalled'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.metaMaskNotInstalled'), { cause: error });
        case error.message === i18n.t('feedbacks.wrongNetwork'):
          ctx.dispatch(
            'common/pushNotification',
            {
              title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.wrongNetwork'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.wrongNetwork'), { cause: error });
        default:
          ctx.dispatch(
            'common/pushNotification',
            {
              // title: i18n.t('feedbacks.walletTitle'),
              body: i18n.t('feedbacks.genericError'),
              duration: 5000,
              payload: {
                type: 'error',
                error,
              },
            },
            { root: true },
          );

          throw new Error(i18n.t('feedbacks.genericError'), { cause: error });
      }
    }
  },

  async connectSuccess(ctx, payload) {
    const { account, smartContract } = payload;
    Vue.$log.info('connectSuccess', payload);

    ctx.commit('setState', ['account', account]);
    ctx.commit('setState', ['smartContract', smartContract]);
  },

  async updateAccount({ commit, dispatch }, account) {
    commit('setState', ['account', account]);
    await dispatch('getBalance', account);
    await dispatch('getTotalSupply');
  },

  async getBalance({ state, commit }, account) {
    try {
      const balance = (await state.web3?.().eth.getBalance(account)) || state.balance;

      // Vue.$log.info('Store: getBalance', balance);

      commit('setState', ['balance', balance]);
    } catch (error) {
      Vue.$log.info(error);
      Sentry.captureException(error);
      // Vue.$log.info('getBalance error:', error);
      throw new Error(i18n.t('feedbacks.couldNotLoadContract'), { cause: error });
    }
  },

  async handleAccountsChanged({ commit, dispatch }, accounts) {
    Vue.$log.info('Store: accountsChanged', accounts);

    if (accounts?.length) {
      dispatch('updateAccount', accounts[0]);
    } else {
      commit('setInitialState');
      await dispatch('fetchConfig');
      await dispatch('getSmartContract');
    }
  },

  async mint(
    {
      commit, //
      state,
      getters,
      dispatch,
    },
    { mintAmount, vm, mintType },
  ) {
    try {
      Vue.$log.info('Store: mint');

      if (isEmptyObject(state.config)) {
        throw Error('Missing config!');
      }

      const { config } = state;

      const cost = config.WEI_COST;
      const gasLimit = config.GAS_LIMIT;

      const totalCostWei = String(cost * mintAmount);
      const totalGasLimit = String(gasLimit * mintAmount);

      if (Number(totalCostWei) > Number(state.balance)) {
        throw Error('Not enough balance');
      }

      Vue.$log.info(`Cost: ${totalCostWei} | Gas limit: ${totalGasLimit}`);

      commit('setState', ['claimingNFT', true]);

      dispatch(
        'common/pushNotification',
        {
          title: i18n.t('feedbacks.mintingTitle'),
          body: i18n.t('feedbacks.mintingNFT'),
          icon: 'icons/icon-gold-brick',
          iconColor: 'text-gray-900',
          // duration: 4000,
        },
        { root: true },
      );

      const merkleProof = getters.getMerkleProof(state.account);

      const tx = {
        from: state.account,
        to: config.CONTRACT_ADDRESS,
        gasLimit: String(totalGasLimit),
        value: totalCostWei,
      };

      let mintCall;

      Vue.$log.info('mintType', mintType);
      if (mintType === 'mint') {
        mintCall = state.smartContract().methods.mint(mintAmount);
      } else if (mintType === 'allowlistMint') {
        mintCall = state.smartContract().methods.allowlistMint(mintAmount, merkleProof);
      }


      const gasAmount = await mintCall.estimateGas(tx);
      tx.gas = gasAmount;

      Vue.$log.info('Store: before mintCall', tx);

      const receipt = await mintCall.send(tx);
      Vue.$log.info('Store: mint receipt', receipt);

      commit('setState', ['claimingNFT', false]);

      await dispatch('getTotalSupply');
      await dispatch('getBalance', state.account);

      // Dismiss any notification that might be visible
      vm.$root.$emit('dismiss-notification');

      // Notify the user
      dispatch(
        'common/pushNotification',
        {
          title: i18n.t('feedbacks.successfulMintTitle'),
          body: i18n.t('feedbacks.successfulMintBody'),
          // icon: 'icons/icon-contract',
          // iconColor: 'text-gray-900',
          payload: {
            type: 'receipt',
            receipt,
          },
        },
        { root: true },
      );

      Sentry.withScope((scope) => {
        Object.keys(receipt).forEach((key) => {
          if (key === 'events') {
            Object.keys(receipt[key]).forEach((eventsKey) => {
              scope.setExtra(`events.${eventsKey}`, receipt[key][eventsKey]);
            });
          } else {
            scope.setExtra(key, receipt[key]);
          }
        });
        Sentry.captureMessage('Successful mint', 'info');
      });

      return {
        receipt,
        message: i18n.t('feedbacks.successfulMintBody'),
      };
    } catch (error) {
      return dispatch('handleBlockchainErrors', { error, vm });
    }
  },

  handleRevertReason({ state }, result) {
    if (result && result.substr(138)) {
      const reason = state.web3().utils.hexToUtf8(`0x${result.substr(138)}`);
      Vue.$log.info('Revert reason:', reason);
      return reason;
    }

    Vue.$log.info('Cannot get reason - No return value');
    return 'Cannot get reason - No return value';
  },


  async handleBlockchainErrors({ state, commit, dispatch }, { error, vm }) {
    Vue.$log.info('handleBlockchainErrors', error.message);

    commit('setState', ['claimingNFT', false]);

    vm.$root.$emit('dismiss-notification');

    // Get fresh balance
    dispatch('getBalance', state.account);

    Vue.$log.info('try/catch', { ...error });

    // Sentry events to track errors
    Sentry.withScope((scope) => {
      Object.keys(error).forEach((key) => {
        scope.setExtra(key, error[key]);
      });
      Sentry.captureMessage(error.message, 'error');
    });

    const revertReason = await dispatch('handleRevertReason', error.data);

    Vue.$log.info('revertReason', revertReason);

    switch (true) {
      case revertReason === 'Address is not allowlisted':
        dispatch(
          'common/pushNotification',
          {
            title: i18n.t('feedbacks.mintingTitle'),
            body: i18n.t('feedbacks.addressIsNotAllowlisted'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.addressIsNotAllowlisted'), {
          cause: error,
        });
      case revertReason === 'max NFT per address exceeded':
        dispatch(
          'common/pushNotification',
          {
            title: i18n.t('feedbacks.mintingTitle'),
            body: i18n.t('feedbacks.maxNftPerAddressExceeded'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.maxNftPerAddressExceeded'), {
          cause: error,
        });

      case error.code === 4001:
        dispatch(
          'common/pushNotification',
          {
            title: i18n.t('feedbacks.mintingTitle'),
            body: i18n.t('feedbacks.deniedTransactionSignature'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.deniedTransactionSignature'), {
          cause: error,
        });

      case error.code === 4900:
        dispatch(
          'common/pushNotification',
          {
            // title: i18n.t('feedbacks.genericTitle'),
            body: i18n.t('feedbacks.walletDisconnected'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.walletDisconnected'), {
          cause: error,
        });

      case error.code === 4901:
        throw new Error(i18n.t('feedbacks.chainDisconnected'), {
          cause: error,
        });

      case error.message === 'Install MetaMask':
        throw new Error(i18n.t('feedbacks.metaMaskNotInstalled'), {
          cause: error,
        });

      case error.message === i18n.t('feedbacks.wrongNetwork'):
        throw new Error(i18n.t('feedbacks.wrongNetwork'), {
          cause: error,
        });

      case error.message.includes('Transaction has been reverted by the EVM:'):
        dispatch(
          'common/pushNotification',
          {
            title: i18n.t('feedbacks.genericTitle'),
            body: i18n.t('feedbacks.genericError'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.genericError'), {
          cause: error,
        });

      case error.message === 'Not enough balance':
        dispatch(
          'common/pushNotification',
          {
            title: i18n.t('feedbacks.mintingTitle'),
            body: i18n.t('feedbacks.notEnoughBalance'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.notEnoughBalance'), {
          cause: error,
        });

      default:
        Vue.$log.info('default case');

        dispatch(
          'common/pushNotification',
          {
            title: i18n.t('feedbacks.genericTitle'),
            body: i18n.t('feedbacks.genericError'),
            duration: 5000,
            payload: {
              type: 'error',
              error,
            },
          },
          { root: true },
        );

        throw new Error(i18n.t('feedbacks.genericError'), {
          cause: error,
        });
    }
  },

  displayWallet({ state, dispatch }) {
    const balanceInEth = state.web3().utils.fromWei(state.balance, 'ether');

    dispatch(
      'common/pushNotification',
      {
        title: i18n.t('feedbacks.walletTitle'),
        icon: 'icons/icon-ethereum',
        iconColor: 'text-gray-900',
        payload: {
          type: 'wallet',
          wallet: {
            account: state.account,
            balance: Number(balanceInEth).toFixed(8),
          },
        },
      },
      { root: true },
    );
  },
};

/**
 * @todo Disconnecting the site from wallet produces an error on `getNFTCost` getter due to web3 state
 * not being cleared. Perhaps we need to listen metamask state changes to reflect it internally
 */

// Getter functions
const getters = {
  isConnected: state => !!state.account,

  getProvider: () => window.ethereum?.providers?.find(p => p.isMetaMask) || window.ethereum,

  getScannerURL: (state) => {
    switch (state.config.NETWORK.ID) {
      case 1:
        return 'https://etherscan.io/';
      case 4:
        return 'https://rinkeby.etherscan.io/';
      default:
        return 'https://etherscan.io/';
    }
  },

  getNetwork: (state) => {
    switch (state.config.NETWORK.ID) {
      case 1:
        return 'mainnet';
      case 4:
        return 'rinkeby';
      default:
        return 'mainnet';
    }
  },

  isClaimingNFT: state => state.claimingNFT,

  getNFTCost: (state) => {
    if (state?.config?.WEI_COST && state.web3) {
      Vue.$log.info('getNFTCost web3', state.web3());
      return state.web3().utils.fromWei(state.config.WEI_COST?.toString(), 'ether');
    }
    return false;
  },

  walletBalance: (state) => {
    if (state.balance) {
      const balanceInEth = state.web3().utils.fromWei(state.balance, 'ether');
      return balanceInEth;
    }
    return false;
  },

  getMerkleProof: (state, getters) => (address) => {
    if (!address) return 'Address not provided.';

    // console.log('getters.getNetwork', getters.getNetwork);

    const leaves = getters.getNetwork === 'rinkeby'
      ? allowlistRinkeby.map(addr => keccak256(addr))
      : allowlistMainnet.map(addr => keccak256(addr));

    const merkleTree = new MerkleTree(leaves, keccak256, {
      sortPairs: true,
    });

    const leaf = keccak256(address);
    const merkleProof = merkleTree.getHexProof(leaf);
    // console.log('address', address);
    // console.log('getters.getNetwork: leaf', leaf);
    // console.log('getters.getNetwork: merkleTree', merkleTree);
    // console.log('getters.getNetwork: merkleProof', merkleProof);
    return merkleProof;
  },

  preMintingEndTime: state => state.config.PRE_MINTING_END_TIME,

  getRemainingTime: () => (deadline) => {
    const endTime = new Date(deadline).getTime();
    const currentTime = new Date().getTime();
    return endTime - currentTime;
  },

  isMintingPhase: () => (deadline) => {
    const endTime = new Date(deadline).getTime();
    const currentTime = new Date().getTime();
    const remainingTime = endTime - currentTime;
    return remainingTime < 0;
  },

  isMintingFinished: state => state.config.IS_MINTING_FINISHED,

  isAllowlistMintingFinished: state => state.config.IS_ALLOWLIST_MINTING_FINISHED,

  getCurrentPhase: (state) => {
    const { currentPhase } = state;

    switch (currentPhase) {
      case 'pre-minting':
        Vue.$log.info('return pre-minting');
        return 'pre-minting';
      case 'allowlist-minting':
        Vue.$log.info('return allowlist-minting');
        return 'allowlist-minting';
      case 'minting-now':
        Vue.$log.info('return minting-now');
        return 'minting-now';
      case 'post-minting':
        Vue.$log.info('return post-minting');
        return 'post-minting';
      default:
        Vue.$log.info('default');
        return '';
    }
  },

  getShortAccountAddres: (state) => {
    const start = state.account?.substring(0, 5);
    const end = state.account?.substring(state.account.length - 4, state.account.length);
    if (start && end) {
      return `${start}...${end}`;
    }
    return '';
  },
};

export default {
  namespaced: true,
  state: () => state,
  getters,
  actions,
  mutations,
};
