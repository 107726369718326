<template>
  <div
    id="mobile-nav"
    class="fixed bottom-0 right-0 mr-8 mb-8 z-20 xl:hidden"
    >
    <div
      id="mobile-nav"
      :class="isOpen ? 'bg-yellow-600' : getSectionStyle.accountBackground"
      class="mobile-nav-button w-12 h-12 transition-all duration-5000 active:scale-75 transform"
      @click="handleMobileNav"
      >
      <div
        class="mobile-nav-line"
        :class="{ 'rotate-45': isOpen, '-translate-y-1.5': !isOpen }"
        />
      <div
        class="mobile-nav-line"
        :class="{ 'opacity-0': isOpen }"
        />
      <div
        class="mobile-nav-line"
        :class="{ '-rotate-45': isOpen, 'translate-y-1.5': !isOpen }"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      getSectionStyle: 'common/getSectionStyle',
    }),
  },
  mounted() {
    this.$root.$on('notification-dismissed', () => {
      this.isOpen = false;
    });

    this.$gtag.event('modal_mobile_nav');
  },
  methods: {
    handleMobileNav() {
      if (this.isOpen) {
       return this.$root.$emit('dismiss-notification');
      }
      this.isOpen = !this.isOpen;

      return this.$store.dispatch('common/pushNotification', {
        // title: this.$t('feedbacks.walletTitle'),
        // body: this.$t('feedbacks.connectToMint'),
        // icon: 'icons/icon-metamask',
        payload: {
          type: 'mobile-navigation',
        },
      });
    },
  },
};
</script>

<style lang="postcss">

.mobile-nav-line {
  @apply block absolute h-1 w-5 bg-current transform transition duration-500 ease-in-out rounded-full
}

.mobile-nav-button {
  @apply  rounded-full shadow-2xl flex flex-col justify-center items-center
}

</style>
