import VueLogger from 'vuejs-logger';

const isProduction = process.env.VUE_APP_ENV === 'production';
const isStaging = process.env.VUE_APP_ENV === 'staging';

const options = {
  isEnabled: true,
  logLevel: isProduction || isStaging ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: ':',
  showConsoleColors: true,
};

export { VueLogger, options };
