<template>
  <gc-io-observer
    id="story"
    class="min-h-screen flex bg-white py-32"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="flex flex-col items-center flex-grow"
      >
      <gc-section-heading
        :pre-title="$t('pages.story.pretitle')"
        :title="$t('pages.story.title')"
        class="pb-12"
        />

      <div class="flex flex-col xl:flex-row flex-grow items-center container">
        <div
          class="flex flex-wrap w-full sm:w-1/2 py-0 px-6 sm:py-14 lg:py-0 my-4 sm:my-12 xl:px-12"
          >
          <div
            v-for="(slide, index) in images"
            :key="index"
            class="w-1/2 p-2 lg:p-6"
            >
            <gc-image
              v-if="true"
              :has-placeholder="true"
              class="
                self-center
                rounded-2xl
                shadow-2xl
                transform
                hover:scale-110
              "
              :file-path="`collection/${slide}.png`"
              />
          </div>
        </div>

        <div
          class="
            flex flex-col
            items-center
            flex-grow
            justify-center
            xl:items-start xl:w-2/3
            px-8
            xl:pl-8
          "
          >
          <gc-heading
            tag="h2"
            color="text-gray-900"
            class="uppercase font-black pb-4"
            >
            {{ $t("pages.story.blurbTitle") }}
          </gc-heading>

          <gc-text
            variant="body-code-sm"
            color="text-gray-900"
            class="whitespace-pre-line tracking-tight"
            >
            {{ $t("pages.story.blurbBody") }}
          </gc-text>

          <gc-button
            tag="a"
            :href="blockchain.config.MARKETPLACE_LINK"
            :has-min-width="false"
            class="min-w-56 my-8 text-sm py-3"
            :block="false"
            svg="icons/icon-opensea"
            svg-align="right"
            svg-color="text-white"
            :text="$t('pages.story.primaryAction')"
            />
        </div>
      </div>
    </div>
  </gc-io-observer>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import gcIoObserver from '@/components/IoObserver.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcIoObserver,
    gcSectionHeading,
  },
  data() {
    return {
      images: [
        'gentlemen-club-story-2',
        'gentlemen-club-story-3',
        'gentlemen-club-story-4',
        'gentlemen-club-story-1',
      ],
    };
  },
  computed: {
    ...mapState({
      blockchain: state => state.blockchain,
    }),
    env() {
      return process.env;
    },
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
  },
};
</script>

<style>
</style>
