<template>
  <gc-page-template>
    <div class="min-h-screen flex py-56">
      <div class="flex flex-col items-center flex-grow px-4">
        <gc-section-heading
          :pre-title="$t('pages.termsOfService.pretitle')"
          :title="$t('pages.termsOfService.title')"
          class="pb-12 text-center"
          color="text-white"
          />
        <div
          class="container flex flex-col justify-center items-center mx-auto"
          >
          <div
            v-for="(legal, key) in $t('pages.termsOfService.legals', {
              returnObjects: true,
            })"
            :key="key"
            class="flex text-white"
            >
            <div class="pt-12 flex-grow flex flex-col justify-center">
              <div
                v-for="(line, index) in legal"
                :key="index"
                class="pb-2"
                >
                <gc-heading
                  tag="h2"
                  color="text-white"
                  class="font-sans font-black text-base pb-4 uppercase text-collection-sizzling-red"
                  >
                  {{ line.title }}
                </gc-heading>

                <gc-text
                  variant="body-code-sm"
                  color="text-white"
                  class="max-w-2xl whitespace-pre-line tracking-tight"
                  >
                  <i18n
                    :path="`pages.termsOfService.legals.${[key]}.${[
                      index,
                    ]}.body`"
                    >
                    <template v-slot:link>
                      <a
                        class="underline"
                        href="https://www.nftlicense.org/"
                        target="_blank"
                        >
                        {{
                          $t(
                            `pages.termsOfService.legals.${[key]}.${[
                              index,
                            ]}.nftLicense`
                          )
                        }}
                      </a>
                    </template>
                  </i18n>
                </gc-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </gc-page-template>
</template>

<script>
import { mapMutations } from 'vuex';
import gcPageTemplate from '@/components/PageTemplate.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcPageTemplate,
    gcSectionHeading,
  },
  created() {
    this.setState(['currentView', 'page-dark']);
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
  },
};
</script>

<style>
</style>
