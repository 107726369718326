<template>
  <div class="flex flex-col items-center">
    <gc-heading
      tag="h2"
      :color="`${color}`"
      class="font-satisfy text-xl md:text-3xl"
      >
      {{ preTitle }}
    </gc-heading>

    <div class="flex items-center">
      <gc-svg-base
        svg="icons/icon-left-leaf"
        :color-class="`fill-current ${color}`"
        />

      <gc-heading
        tag="h2"
        :color="`${color}`"
        class="font-black uppercase text-3xl md:text-7xl px-4"
        >
        {{ title }}
      </gc-heading>

      <gc-svg-base
        svg="icons/icon-right-leaf"
        :color-class="`fill-current ${color}`"
        />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preTitle: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: 'text-gray-900',
    },
  },
};
</script>

<style>
</style>
