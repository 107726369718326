<template>
  <gc-io-observer
    id="faq"
    class="min-h-screen flex bg-white py-32"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="flex flex-col items-center flex-grow px-8"
      >
      <gc-section-heading
        :pre-title="$t('pages.faq.pretitle')"
        :title="$t('pages.faq.title')"
        />

      <div class="pt-12">
        <div
          v-for="(line, key) in $t('pages.faq.list', {
            returnObjects: true,
          })"
          :key="key"
          class="flex text-white"
          >
          <div class="pb-12">
            <gc-heading
              tag="h2"
              color="text-white"
              class="font-sans font-black md:text-base pb-4 uppercase text-collection-sizzling-red"
              >
              {{ line.title }}
            </gc-heading>

            <gc-text
              variant="body-code-sm"
              color="text-gray-900"
              class="max-w-2xl whitespace-pre-line tracking-tight"
              >
              {{ line.body }}
            </gc-text>
          </div>
        </div>
      </div>
    </div>
  </gc-io-observer>
</template>

<script>
import { mapMutations } from 'vuex';
import gcIoObserver from '@/components/IoObserver.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcIoObserver,
    gcSectionHeading,
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
  },
};
</script>

<style>
</style>
