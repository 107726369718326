<template>
  <div class="flex flex-1 flex-col transition-set items-center sm:items-start">
    <div class="mb-4">
      <gc-svg-base
        color-class="fill-current text-white"
        svg="icons/icon-gold-brick"
        />
    </div>

    <gc-heading
      tag="h1"
      color="text-white"
      class="
        uppercase
        font-bold
        pb-2
        sm:text-left
        text-center text-4xl
        sm:text-5xl
      "
      >
      {{ $t("pages.landing.allowlistMintingTitle") }}
    </gc-heading>

    <gc-text
      variant="body-code-sm"
      color="text-white"
      class="sm:text-left text-center pb-6 whitespace-pre-line"
      >
      {{ $t("pages.landing.mintingNowBody") }}
    </gc-text>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      blockchain: state => state.blockchain,
    }),
    ...mapGetters({
      isMintingFinished: 'blockchain/isMintingFinished',
    }),
  },
  mounted() {
    if (this.isMintingFinished) {
      this.setState(['currentPhase', 'post-minting']);
    }
  },
  methods: {
    ...mapMutations({
      setState: 'blockchain/setState',
    }),
  },
};
</script>

<style>
</style>
