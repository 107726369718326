
export function toKebapCase(str) {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
}

export function camelCase(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}


export function getBrowserLanguage() {
  return window.navigator.language.split('-')[0].toLowerCase();
}


export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}


export function isBase64(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function convertMilliseconds(timeFrame, duration) {
  const ms = 1000;
  const minute = 60;
  const hour = 60;
  const day = 24;
  const week = 7;

  if (timeFrame && duration) {
    switch (timeFrame) {
      case 'second':
        return duration / ms;
      case 'minute':
        return duration / minute / ms;
      case 'hour':
        return duration / hour / minute / ms;
      case 'day':
        return duration / day / hour / minute / ms;
      case 'week':
        return duration / week / day / hour / minute / ms;
      default:
        return null;
    }
  }
  return null;
}


export function getMilliseconds(timeFrame, duration) {
  const ms = 1000;
  const minute = 60;
  const hour = 60;
  const day = 24;
  const week = 7;

  if (timeFrame && duration) {
    switch (timeFrame) {
      case 'minute':
        return duration * minute * ms;
      case 'hour':
        return duration * hour * minute * ms;
      case 'day':
        return duration * day * hour * minute * ms;
      case 'week':
        return duration * week * day * hour * minute * ms;
      default:
        return null;
    }
  }
  return null;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function sentenceCase(string) {
  return string?.[0].toUpperCase() + string.slice(1);
}

export function toAscii(data) {
  if (!data) return false;
  const hex = data.toString().substr(138);
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}
