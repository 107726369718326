import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import { isEmptyObject, sentenceCase } from '@/mixins/utils';

import Home from '@/views/Home.vue';
import Lobby from '@/views/Lobby.vue';
import TermsOfService from '@/views/TermsOfService.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import Provenance from '@/views/Provenance.vue';
import PageNotFound from '@/views/PageNotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/lobby',
    redirect: { name: 'home' },
    component: Lobby,
    meta: {
      title: 'Lobby',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: TermsOfService,
    meta: {
      title: 'Privacy Policy',
    },
  },
  {
    path: '/provenance',
    name: 'provenance',
    component: Provenance,
    meta: {
      title: 'Provenance',
    },
  },
  {
    path: '/page-not-found',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      title: 'Page Not Found',
    },
  },
  {
    path: '*',
    redirect: '/page-not-found',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to?.params?.disableScroll) {
      // console.log('disableScroll');
      // eslint-disable-next-line no-param-reassign
      delete to.params.disableScroll;
      return false;
    }

    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      const el = document.querySelector(to.hash);
      const rect = el?.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const top = rect?.top + scrollTop;
      // console.log('Will scrollTo');

      // document.title = `The Gentlemen Club – ${to.meta.title}`;

      return window.scrollTo({ top, behavior: 'smooth' });
    }
    // Go to the top of the page if no hash
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (process.env.VUE_APP_IS_LOBBY_OPEN === 'true' && to.name !== 'lobby') {
    return next({ name: 'lobby' });
  }

  if (process.env.VUE_APP_IS_LOBBY_OPEN === 'false' && isEmptyObject(store.state.blockchain.config)) {
    await store.dispatch('blockchain/fetchConfig');
  }

  return next();
});

router.afterEach((to) => {
  const title = to?.hash?.split('#')[1] || to?.meta?.title;


  document.title = `The Gentlemen Club NFT ${title ? `– ${sentenceCase(title)}` : ''}`;
});
export default router;
