<template>
  <gc-page-template>
    <div class="min-h-screen flex py-56">
      <div class="flex flex-col items-center flex-grow px-4">
        <gc-section-heading
          :pre-title="$t('pages.provenance.pretitle')"
          :title="$t('pages.provenance.title')"
          class="pb-12 text-center"
          color="text-white"
          />
        <div
          class="container flex flex-col justify-center items-center mx-auto"
          >
          <div
            class="flex flex-col items-start flex-grow justify-center xl:w-2/3"
            >
            <gc-text
              variant="body-code-sm"
              color="text-white"
              class="whitespace-pre-line tracking-tight"
              >
              {{ $t("pages.provenance.blurbBody") }}
            </gc-text>

            <div class="pt-8 overflow-x-auto break-all">
              <gc-text
                variant="body-code-sm"
                color="text-white"
                class="whitespace-pre-line tracking-tight pb-2"
                >
                {{ $t("pages.provenance.contractAddress") }}
                {{ blockchain.config.CONTRACT_ADDRESS }}
              </gc-text>
              <gc-text
                variant="body-code-sm"
                color="text-white"
                class="whitespace-pre-line tracking-tight pb-2"
                >
                {{ $t("pages.provenance.finalProof") }} {{ provenance }}
              </gc-text>
            </div>

            <div class="pt-8 flex flex-col justify-center items-center w-full">
              <gc-text
                variant="body-code-sm"
                color="text-white"
                class="whitespace-pre-line tracking-tight pb-2"
                >
                {{ $t("pages.provenance.concatenatedHashString") }}
              </gc-text>
              <gc-text
                variant="body-code-sm"
                color="text-white"
                class="
                  whitespace-pre-line
                  tracking-tight
                  pb-2
                  overflow-x-hidden
                  max-w-xs
                  sm:max-w-sm
                  md:max-w-2xl
                  lg:max-w-3xl
                  h-44
                  break-all
                "
                >
                {{ concatenatedHashString }}
              </gc-text>
            </div>
          </div>

          <div
            class="pt-32 overflow-x-auto max-w-xs sm-max-w-sm md:max-w-2xl whitespace-nowrap"
            >
            <gc-text
              v-for="(el, i) in accumulatedHashString"
              :key="i"
              variant="body-code-sm"
              color="text-white"
              >
              {{ i + 1 }} – {{ el }}
            </gc-text>
          </div>
        </div>
      </div>
    </div>
  </gc-page-template>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import gcPageTemplate from '@/components/PageTemplate.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcPageTemplate,
    gcSectionHeading,
  },
  data() {
    return {
      accumulatedHashString: [],
      provenance: null,
      concatenatedHashString: null,
    };
  },
  computed: {
    ...mapState({
      blockchain: state => state.blockchain,
    }),
  },
  async created() {
    this.setState(['currentView', 'page-dark']);

    const { provenance, concatenatedHashString } = await this.$store.dispatch(
      'blockchain/fetchProvenance',
    );
    this.provenance = provenance;
    this.concatenatedHashString = concatenatedHashString;

    this.accumulatedHashString = (
      await this.$store.dispatch('blockchain/fetchAccumulatedHashString')
    ).accumulatedHashString;
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
  },
};
</script>

<style>
</style>
