<template>
  <gc-io-observer
    id="collection"
    class="min-h-screen flex py-32"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="flex flex-col items-center flex-grow"
      >
      <gc-section-heading
        :pre-title="$t('pages.collection.pretitle')"
        :title="$t('pages.collection.title')"
        color="text-white"
        />
      <!-- Carousel -->
      <div
        v-if="true"
        class="w-full flex-grow flex flex-col justify-center py-12"
        >
        <div
          class="collection-carousel overflow-hidden h-72 md:h-96 outline-none"
          >
          <div
            v-for="(slide, index) in images"
            :key="index"
            class="carousel-cell w-72 md:w-96 mx-8"
            >
            <!-- <gc-text color="white">
              {{ slide }}
            </gc-text> -->
            <gc-image
              v-if="true"
              :has-placeholder="true"
              class="self-center rounded-full"
              :file-path="`collection/${slide}.png`"
              />
          </div>
        </div>
      </div>

      <!-- Carousel controls -->
      <div class="flex justify-center">
        <gc-button
          variant="primary-slim-muted"
          :has-min-width="false"
          class="min-w-28 mx-2"
          :block="false"
          svg="icons/icon-left-arrow"
          svg-align="left"
          svg-color="text-white"
          @click.native="flkty.previous()"
          />
        <gc-button
          variant="primary-slim-muted"
          :block="false"
          svg="icons/icon-right-arrow"
          svg-align="left"
          svg-color="text-white"
          :has-min-width="false"
          class="min-w-28 mx-2"
          @click.native="flkty.next()"
          />
      </div>

      <!-- Text -->
      <div
        class="
          flex flex-col
          w-full
          items-center
          text-left
          sm:text-center
          pt-12
          px-8
          md:px-0
        "
        >
        <gc-heading
          tag="h2"
          color="text-white"
          class="uppercase font-black pb-2"
          >
          {{ $t("pages.collection.blurbTitle") }}
        </gc-heading>

        <gc-text
          variant="body-code-sm"
          color="text-white"
          class="max-w-3xl whitespace-pre-line tracking-tight"
          >
          {{ $t("pages.collection.blurbBody") }}
        </gc-text>
      </div>
    </div>
  </gc-io-observer>
</template>

<script>
import { mapMutations } from 'vuex';
import Flickity from 'flickity';
import gcIoObserver from '@/components/IoObserver.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcIoObserver,
    gcSectionHeading,
  },
  data() {
    return {
      images: [
        'gentlemen-club-collection-5',
        'gentlemen-club-collection-6',
        'gentlemen-club-collection-10',
        'gentlemen-club-collection-7',
        'gentlemen-club-collection-3',
        'gentlemen-club-collection-8',
        'gentlemen-club-collection-9',
        'gentlemen-club-collection-4',
        'gentlemen-club-collection-11',
        'gentlemen-club-collection-12',
        'gentlemen-club-collection-1',
        'gentlemen-club-collection-13',
        'gentlemen-club-collection-17',
        'gentlemen-club-collection-14',
        'gentlemen-club-collection-2',
        'gentlemen-club-collection-15',
        'gentlemen-club-collection-20',
        'gentlemen-club-collection-16',
        'gentlemen-club-collection-18',
        'gentlemen-club-collection-19',
      ],
    };
  },
  mounted() {
    this.initFlickity();
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
    initFlickity() {
      const carousel = document.querySelector('.collection-carousel');
      // this.$log.info('carousel', carousel);

      if (carousel) {
        this.flkty = new Flickity(carousel, {
          dragThreshold: 1,
          initialIndex: 0,
          percentPosition: true,
          prevNextButtons: false,
          pageDots: false,
          wrapAround: true,
          // autoPlay: 6000,
          autoPlay: false,
          pauseAutoPlayOnHover: false,
          setGallerySize: false,
        });

        // Flickity helper to prevent vertical scroll while swiping left to right.
        this.flkty.on('pointerDown', (e) => {
          e.preventDefault();
        });

        this.flkty.on('dragStart', () => {
          document.ontouchmove = (e) => {
            e.preventDefault();
          };
        });

        this.flkty.on('dragEnd', () => {
          document.ontouchmove = (e) => {
            this.$log.info('dragEnd:ontouchmove', e);
            return true;
          };
        });
      }
    },
  },
};
</script>

<style lang="postcss">
/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-viewport {
  position: relative;
  width: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* button */
/* smaller, dark, rounded square */
.flickity-prev-next-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #333;
}
.flickity-prev-next-button:hover {
  background: #f90;
}
/* arrow color */
.flickity-prev-next-button .arrow {
  fill: white;
}
.flickity-prev-next-button.no-svg {
  color: white;
}
/* position outside */
.flickity-prev-next-button.previous {
  left: -40px;
}
.flickity-prev-next-button.next {
  right: -40px;
}
</style>
