<template>
  <gc-io-observer
    id="team"
    class="min-h-screen flex py-32"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="flex flex-col items-center flex-grow"
      >
      <gc-section-heading
        :pre-title="$t('pages.team.pretitle')"
        :title="$t('pages.team.title')"
        color="text-white"
        />

      <div
        class="flex flex-col lg:flex-row flex-grow items-center lg:max-w-7xl"
        >
        <div class="flex flex-wrap w-full lg:w-1/2 py-14 lg:py-0 px-8">
          <div
            v-for="(slide, index) in images"
            :key="index"
            class="w-1/2 p-2 sm:p-6"
            >
            <gc-image
              :has-placeholder="true"
              class="
                h-52
                sm:h-96
                self-center
                rounded-2xl
                shadow-2xl
                transform
                hover:scale-110
                object-cover
              "
              :file-path="`collection/${slide}.png`"
              />
          </div>
        </div>

        <div
          class="
            flex flex-col
            items-center
            flex-grow
            justify-center
            lg:items-start lg:w-1/2
            px-8
            lg:pl-8 lg:pr-36
          "
          >
          <gc-heading
            tag="h2"
            color="text-white"
            class="uppercase font-black pb-2"
            >
            {{ $t("pages.team.blurbTitle") }}
          </gc-heading>

          <gc-text
            variant="body-code-sm"
            color="text-white"
            class="max-w-2xl whitespace-pre-line tracking-tight"
            >
            {{ $t("pages.team.blurbBody") }}
          </gc-text>
        </div>
      </div>
    </div>
  </gc-io-observer>
</template>

<script>
import { mapMutations } from 'vuex';
import gcIoObserver from '@/components/IoObserver.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcIoObserver,
    gcSectionHeading,
  },
  data() {
    return {
      images: [
        'gentlemen-club-team-1',
        'gentlemen-club-team-2',
      ],
    };
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
  },
};
</script>

<style>
</style>
