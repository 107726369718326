<template>
  <component
    :is="tag"
    :id="id"
    :data-ruid="ruid"
    :class="getButtonClass"
    role="button"
    :href="tag === 'a' ? href : null"
    :target="tag === 'a' && href ? '_blank' : null"
    :disabled="disabled"
    >
    <!-- {{ !!href }} -->
    <gc-svg-base
      v-if="svgAlign === 'left' && !isLoading"
      :svg="svg"
      class="inline"
      :class="[text ? 'mr-3' : null]"
      :color-class="getSvgClass"
      />

    <div class="absolute flex justify-center w-5 h-5">
      <gc-svg-base
        svg="icons/icon-loading"
        class="absolute h-5 w-5 duration-500 ease-in-out transition-all loading-svg"
        :class="getLoadingClass"
        />
    </div>

    <slot v-if="!text" />
    <span
      v-else
      :class="isLoading ? 'opacity-0' : 'opacity-100'"
      class="duration-300 ease-in-out transition-all"
      >
      {{ text }}
    </span>


    <gc-svg-base
      v-if="svgAlign === 'right' && !isLoading"
      :svg="svg"
      class="inline"
      :class="[text ? 'ml-3' : null]"
      :color-class="getSvgClass"
      />
  </component>
</template>

<script>
import commonProps from '@/mixins/commonProps';

// Valid variants
const variantProps = [
  'primary', //
  'primary-slim',
  'primary-slim-muted',
  'secondary',
  'red',
  'light',
  'plain',
  'transparent',
];

export default {
  mixins: [commonProps],
  props: {
    variant: {
      type: String,
      required: false,
      default: 'primary',
      validator: value => variantProps.includes(value),
    },
    text: {
      type: [String, Function, Array],
      default: null,
      required: false,
    },
    hasMinWidth: {
      type: Boolean,
      default: true,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    tag: {
      type: String,
      default: 'button',
      required: false,
    },
    href: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    getSvgClass() {
      return `fill-current ${this.svgColor}`;
    },
    getLoadingClass() {
      return [
        this.isLoading ? 'opacity-100 animate-spin' : 'opacity-0',
        this.variant === 'primary' ? 'text-white' : '',
        this.variant === 'secondary' ? 'text-primary-darker' : '',
      ];
    },
    getButtonClass() {
      return [
        'btn',
        'transition',
        'outline-none focus:outline-none',
        this.text && !this.svg ? 'py-3 px-6' : null,
        this.svg && !this.text ? 'btn-icon p-2 rounded-full' : null,
        this.svg && this.text ? 'py-3 px-6' : null,
        this.svg ? 'p-2' : null,
        this.block ? 'block w-full' : '',
        this.disabled ? 'disabled:opacity-50 cursor-not-allowed' : '',
        this.hasMinWidth ? 'min-w-xxxs' : '',
        this.variant === 'primary' ? 'btn-primary' : null,
        this.variant === 'primary-slim' ? 'btn-primary btn-slim' : null,
        this.variant === 'primary-slim-muted' ? 'btn-muted btn-slim' : null,
        this.variant === 'secondary' ? 'btn-secondary' : null,
        this.variant === 'red' ? 'btn-red' : null,
        this.variant === 'light' ? 'btn-light' : null,
        this.variant === 'plain' ? '' : null,
        this.variant === 'transparent' ? 'text-primary-light' : null,
      ];
    },
  },
};
</script>

<style lang="postcss">
.btn {
  @apply flex font-black rounded-full items-center justify-center relative uppercase;
}

.transition {
  @apply transition-all ease-out duration-500;
}

.btn-primary {
  @apply bg-gray-900 text-white border border-gray-900;
}

.btn-primary:hover {
  @apply bg-gray-700 text-white border-gray-700;
}

.btn-muted {
  @apply bg-black bg-opacity-20 text-white border-black;
}

.btn-muted:hover {
  @apply bg-black bg-opacity-40 text-white border-black;
}

.btn-slim {
  @apply text-xs px-6 py-2;
}
.btn-secondary {
  @apply bg-gray-200 text-gray-800 border border-gray-200;
}

.btn-secondary:hover {
  @apply bg-gray-300 border-gray-300;
}

.btn-red {
  @apply bg-secondary-red text-white border border-secondary-red;
}

.btn-red:hover {
  @apply bg-secondary-red border-secondary-red opacity-80;
}

.btn-light {
  @apply bg-gray-100 text-gray-900 border border-gray-100;
}

.btn-light:hover {
  @apply bg-gray-200 border-gray-200;
}

.btn:focus,
.btn-icon:focus {
  @apply outline-none;
}

.btn-icon {
  @apply rounded-full;
}

</style>
