<template>
  <section
    :id="id"
    class="io-item"
    >
    <slot />
  </section>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      io: null,
      previousY: 0,
      previousRatio: 0,
    };
  },
  computed: {
    ioOptions() {
      return {
        rootMargin: '0px 0px 0px 0px',
        // threshold: [0, 0.1, 0.2, 0.8, 0.9, 1],
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        // threshold: [0.05, 0.75, 0.95],
      };
    },
    getElement() {
      return document.getElementById(this.id);
    },
  },
  mounted() {
    setTimeout(() => {
      this.initIntersectionObserver();
    }, 250);
  },
  beforeDestroy() {
    if (this.io) {
      this.io.disconnect();
    }
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    initIntersectionObserver() {
      const { ioCallback, ioOptions } = this;

      this.io = new IntersectionObserver(ioCallback, ioOptions);
      this.io.observe(this.getElement);
    },
    ioCallback(entries) {
      entries.forEach((entry) => {
        const currentY = entry.boundingClientRect.y;
        const currentRatio = entry.intersectionRatio;
        const currentBottom = entry.boundingClientRect.bottom;

        const { isIntersecting } = entry;
        const { previousRatio, previousY } = this;
        // const state = document.querySelector('.observer__state');

        // this.$log.info(
        //   entry.target.id,
        //   'entry.boundingClientRect:',
        //   // entry.boundingClientRect,
        //   `isIntersecting: ${isIntersecting}`,
        //   `currentY: ${currentY}`,
        //   `currentRatio: ${currentRatio}`,
        //   `currentBottom: ${currentBottom}`,
        // );

        this.$emit('io-entry', entry);

        // Scrolling down/up
        if (currentY < previousY) {
          if (currentRatio > previousRatio && isIntersecting && currentY < 200) {
            // state.textContent = 'Scrolling down enter';
            // this.$log.warn('Scrolling down enter');
            this.$emit('io-callback', entry);
          } else {
            // state.textContent = 'Scrolling down leave';
          }
        } else if (currentY > previousY) {
          if (currentRatio < previousRatio) {
            // this.$log.warn('Scrolling up leave', entry.target.id, currentBottom);
            // state.textContent = 'Scrolling up leave';
          } else if (isIntersecting && currentBottom > 50) {
            // this.$log.warn('Scrolling up enter', entry.target.id);
            this.$emit('io-callback', entry);
            // state.textContent = 'Scrolling up enter';
          }
        }

        this.previousY = currentY;
        this.previousRatio = currentRatio;
      });
    },
  },
};
</script>

<style>
</style>
