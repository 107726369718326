<template>
  <ul class="px-8">
    <li
      v-for="(item, i) in nav"
      :key="i"
      class="flex justify-center lg:inline-block px-4 uppercase pb-8 lg:pb-0"
      >
      <router-link
        v-slot="{ href, navigate, isActive }"
        :to="item.route"
        class="group"
        custom
        >
        <a
          :href="href"
          @click="navigate"
          >
          <gc-text
            variant="body-code-xs"
            :class="[
              isActive ? ' border-red-500' : 'border-transparent',
              textHoverColor,
            ]"
            class="
              border-b-2
              transition-all
              duration-500
              ease-out
              delay-100
              inline
              pb-2
            "
            :color="textColor"
            @click.native="$root.$emit('dismiss-notification')"
            >
            {{ item.name }}
          </gc-text>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      required: false,
      default: 'text-white',
    },
    textHoverColor: {
      type: String,
      required: false,
      default: 'group-hover:text-gray-900',
    },
  },
  data() {
    return {
      nav: [
        {
          name: 'provenance',
          route: 'provenance',
        },
        {
          name: 'terms of service',
          route: 'terms-of-service',
        },
      ],
    };
  },
  methods: {
    handleNavigate(navigate) {
      navigate();
      this.$root.$emit('dismiss-notification');
    },
  },
};
</script>

<style>
</style>
