<template>
  <div v-if="!hasCountDownFinished">
    <div class="flex">
      <div
        v-for="(time, key, i) in countdown"
        :key="i"
        class="flex flex-col items-center mx-1"
        >
        <div
          class="
            flex
            justify-center
            items-center
            bg-black bg-opacity-20
            w-12
            h-16
            rounded-xl
          "
          >
          <gc-text
            color="text-white"
            variant="body-3xl"
            >
            {{ time }}
          </gc-text>
        </div>

        <gc-text
          color="text-white"
          variant="body-code-xxxs-bold"
          class="uppercase"
          >
          {{ key }}
        </gc-text>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      hasCountDownFinished: false,
      countdown: {},
      // deadline: 'Dec 14, 2021 18:30:00',
    };
  },
  computed: {
    ...mapGetters({
      preMintingEndTime: 'blockchain/preMintingEndTime',
      getRemainingTime: 'blockchain/getRemainingTime',
      isMintingPhase: 'blockchain/isMintingPhase',
      isAllowlistMintingFinished: 'blockchain/isAllowlistMintingFinished',
    }),
  },
  created() {
    this.startCountdown();
  },
  methods: {
    ...mapMutations({
      setState: 'blockchain/setState',
    }),
    startCountdown() {
      // set our end time
      const endTime = new Date(this.preMintingEndTime).getTime();

      // calculate remaining time from now until deadline
      const getRemainingTime = (deadline) => {
        const currentTime = new Date().getTime();
        return deadline - currentTime;
      };

      // pad value with zero
      const pad = value => `0${Math.floor(value)}`.slice(-2);

      // show time repeatedly
      const showTime = () => {
        const remainingTime = getRemainingTime(endTime);
        const seconds = pad((remainingTime / 1000) % 60);
        const minutes = pad((remainingTime / (60 * 1000)) % 60);
        const hours = pad((remainingTime / (60 * 60 * 1000)) % 24);
        const days = pad(remainingTime / (24 * 60 * 60 * 1000));

        // ensure clock only updates if a second or more is remaining
        if (remainingTime >= 1000) {
          const result = {
            days,
            hours,
            minutes,
            seconds,
          };

          this.countdown = result;

          requestAnimationFrame(showTime);
        } else {
          this.hasCountDownFinished = true;
          this.$log.info('Countdown timer');

          if (this.isAllowlistMintingFinished) {
            this.setState(['currentPhase', 'minting-now']);
          } else {
            this.setState(['currentPhase', 'allowlist-minting']);
          }
        }
      };

      // kick it all off
      requestAnimationFrame(showTime);
    },
  },
};
</script>

<style>
</style>
