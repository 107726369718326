import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { getRandomInt } from '@/mixins/utils';
import i18n from '@/i18n';

const initialState = {
  currentView: 'landing',
  version: process.env.VERSION,
  notificationQueue: [],
};

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// State object
const state = {
  ...initialState,
};

// Mutations
const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },
  pushToNotificationQueue(state, payload) {
    state.notificationQueue.push(payload);
  },
  spliceFromNotificationQueue(state, index) {
    state.notificationQueue.splice(index, 1);
  },
};

// Actions
const actions = {
  handleViewObserver({ commit }, payload) {
    const { event, vm } = payload;
    const { id } = event.target;
    commit('setState', ['currentView', id]);

    if (vm && vm.$route.hash !== `#${id}`) {
      // console.log(`Will replace hash with #${id}`);
      vm.$router.replace({
        hash: `#${id}`,
        params: { disableScroll: true },
      }).catch(() => { });
    }
  },


  pushNotification({ commit }, payload) {
    let notification = payload;

    const sampleNotifications = [{
      messageId: uuidv4(),
      // title: i18n.t('feedbacks.genericTitle'),
      title: i18n.t('feedbacks.successfulMintTitle'),
      body: i18n.t('feedbacks.successfulMintBody'),
      // icon: 'icons/icon-contract',
      iconColor: 'text-gray-900',
      // body: 'Sample message.',
      // duration: 6000,
      payload: {
        type: 'receipt',
        receipt: {
          blockHash: '0x4d76c16ca6a9288d56b83956bb33c8a9a102757f3c782a7a6dd1cd247093b748',
          blockNumber: 9804343,
          contractAddress: null,
          cumulativeGasUsed: 8189855,
          effectiveGasPrice: '0x9502f908',
          from: '0x27e623b346da171d7a080ddc36341290bffa17aa',
          gasUsed: 158193,
          logsBloom: '0x00000080000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000800000200000000000000000000000000000008000000000000000000000010000000000000000000000000020000000000000000000800010000000000000000000010000000000000000000000000000000000000000000000000400000000000200000000000000000000000000000000000000100000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000200000',
          status: true,
          to: '0xb4cd91a41e2bf5947cbd2c0cb8722dc3772f64f5',
          transactionHash: '0x3b3bd5a9f88697d99b85ab4e8a654c28d97ee2c2248df7e8f763e0cf6e5a6e70',
          transactionIndex: 16,
          type: '0x2',
          events: {
            Transfer: {
              address: '0xB4cd91A41E2bf5947CBD2c0cB8722Dc3772F64f5',
              blockHash: '0x4d76c16ca6a9288d56b83956bb33c8a9a102757f3c782a7a6dd1cd247093b748',
              blockNumber: 9804343,
              logIndex: 102,
              removed: false,
              transactionHash: '0x3b3bd5a9f88697d99b85ab4e8a654c28d97ee2c2248df7e8f763e0cf6e5a6e70',
              transactionIndex: 16,
              id: 'log_164c3fec',
              returnValues: {
                0: '0x0000000000000000000000000000000000000000',
                1: '0x27E623b346DA171d7a080ddc36341290BffA17AA',
                2: '29',
                from: '0x0000000000000000000000000000000000000000',
                to: '0x27E623b346DA171d7a080ddc36341290BffA17AA',
                tokenId: '29',
              },
              event: 'Transfer',
              signature: '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
              raw: {
                data: '0x',
                topics: [
                  '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
                  '0x0000000000000000000000000000000000000000000000000000000000000000',
                  '0x00000000000000000000000027e623b346da171d7a080ddc36341290bffa17aa',
                  '0x000000000000000000000000000000000000000000000000000000000000001d',
                ],
              },
            },
          },
        },
      },
    }];

    if (isEmpty(notification)) {
      notification = sampleNotifications[getRandomInt(sampleNotifications.length)];
    }

    commit('pushToNotificationQueue', notification);
  },
};

// Getter functions
const getters = {
  landingColors: () => {
    const colors = [
      'bg-collection-kobi',
      'bg-collection-space-cadet',
      'bg-collection-brunswick-green',
      'bg-collection-coral-red',
      'bg-collection-sizzling-red',
      'bg-collection-ochre',
      'bg-collection-true-blue',
      'bg-collection-light-carmine-pink',
    ];

    const randomInt = getRandomInt(colors.length);

    return colors[randomInt];
  },
  getSectionStyle(state, getters) {
    // Vue.$log.info('getSectionStyle');

    switch (state.currentView) {
      case 'landing':
        return {
          background: getters.landingColors,
          text: 'text-white',
          textHover: 'group-hover:text-gray-900',
          mobileNavText: 'text-gray-900',
          mobileNavTextHover: 'group-hover:text-green-600',
          iconHover: 'hover:text-gray-900',
          button: 'primary',
          accountBackground: 'bg-gray-900',
        };
      case 'collection':
        return {
          background: 'bg-gray-900',
          text: 'text-green-500',
          textHover: 'group-hover:text-white',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-white',
          button: 'red',
          accountBackground: 'bg-red-500',
        };
      case 'story':
        return {
          background: 'bg-white',
          text: 'text-gray-900',
          textHover: 'group-hover:text-red-500',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-red-500',
          button: 'primary',
          accountBackground: 'bg-gray-900',
        };
      case 'roadmap':
        return {
          background: 'bg-gray-900',
          text: 'text-green-500',
          textHover: 'group-hover:text-white',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-white',
          button: 'red',
          accountBackground: 'bg-red-500',
        };
      case 'faq':
        return {
          background: 'bg-white',
          text: 'text-gray-900',
          textHover: 'group-hover:text-red-500',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-red-500',
          button: 'primary',
          accountBackground: 'bg-gray-900',
        };
      case 'team':
        return {
          background: 'bg-gray-900',
          text: 'text-green-500',
          textHover: 'group-hover:text-white',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-white',
          button: 'red',
          accountBackground: 'bg-red-500',
        };
      case 'partners':
        return {
          background: 'bg-gray-900',
          text: 'text-green-500',
          textHover: 'group-hover:text-white',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-white',
          button: 'red',
          accountBackground: 'bg-red-500',
        };
      case 'page-dark':
        return {
          background: 'bg-gray-900',
          text: 'text-green-500',
          textHover: 'group-hover:text-white',
          mobileNavText: 'text-gray-900',
          iconHover: 'hover:text-white',
          button: 'red',
          accountBackground: 'bg-red-500',
        };
      default:
        break;
    }
    return '';
  },
  getSocials: (state, getters, rootState) => {
    const socials = [
      {
        name: 'Twitter',
        icon: 'icon-twitter',
        href: 'https://www.twitter.com/TheGentlemenNFT',
      },
      {
        name: 'Instagram',
        icon: 'icon-instagram',
        href: 'https://www.instagram.com/gentlemenclub.nft',
      },
      // {
      //   name: 'Discord',
      //   icon: 'icon-discord',
      //   href: 'https://www.discord.com',
      // },
      {
        name: 'Opensea',
        icon: 'icon-opensea',
        href: rootState.blockchain.config.MARKETPLACE_LINK,
      },
    ];

    return socials;
  },
};

export default {
  namespaced: true,
  state: () => (state),
  getters,
  actions,
  mutations,
};
