<template>
  <div
    class="
      fixed
      w-full
      py-6
      transition-all
      duration-500
      delay-200
      ease-out
    "
    :class="getSectionStyle.background"
    style="transform: translate3d(0px, 0px, 0px)"
    >
    <!-- Header -->
    <div
      v-if="true"
      class="
        flex flex-grow
        items-center
        justify-center
        md:justify-between
        container
        mx-auto
        px-4
      "
      >
      <router-link to="/#landing">
        <gc-svg-base
          svg="gentlemen-club"
          class="w-44 sm:w-56"
          :color-class="`fill-current ${getSectionStyle.text} transition-all duration-500 ease-out`"
          />
      </router-link>

      <div
        v-if="true"
        class="
          hidden
          md:flex
          flex-grow
          justify-end
          xl:justify-between
          items-center
        "
        >
        <gc-navigation
          class="hidden xl:flex"
          :text-color="getSectionStyle.text"
          :text-hover-color="getSectionStyle.textHover"
          />

        <div class="pr-3 hidden md:flex place-self-end">
          <a
            v-for="(item, i) in getSocials"
            :key="i"
            :href="item.href"
            target="_blank"
            >
            <gc-svg-base
              :color-class="`mx-4 fill-current ${getSectionStyle.text} ${getSectionStyle.iconHover}
              transition-set delay-150`"
              :svg="`icons/${item.icon}`"
              />
          </a>
        </div>
      </div>

      <div class="hidden md:flex">
        <gc-button
          v-if="isConnected"
          :text="getShortAccountAddres"
          class="flex-shrink min-w-36 delay-200"
          :block="false"
          :variant="getSectionStyle.button"
          :has-min-width="false"
          svg="icons/icon-checkmark"
          svg-align="right"
          svg-color="text-white"
          @click.native="$store.dispatch('blockchain/displayWallet'), $gtag.event('btn_connected')"
          />

        <gc-button
          v-else-if="!blockchain.config.IS_MINTING_FINISHED"
          :text="$t('commons.connect')"
          class="flex-shrink min-w-36 delay-200"
          :block="false"
          :variant="getSectionStyle.button"
          :has-min-width="false"
          @click.native="$store.dispatch('blockchain/connect').catch(() => {}), $gtag.event('btn_connect')"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import gcNavigation from '@/components/Navigation.vue';

export default {
  components: {
    gcNavigation,
  },
  computed: {
    ...mapState({
      currentView: st => st.common.currentView,
      blockchain: state => state.blockchain,
    }),
    ...mapGetters({
      getSectionStyle: 'common/getSectionStyle',
      getSocials: 'common/getSocials',
      getCurrentPhase: 'blockchain/getCurrentPhase',
      isConnected: 'blockchain/isConnected',
      getShortAccountAddres: 'blockchain/getShortAccountAddres',
    }),
  },
};
</script>
