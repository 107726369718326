<template>
  <div
    id="name-carousel"
    class="relative"
    >
    <transition
      name="view-fade"
      mode="out-in"
      >
      <div
        :key="name"
        class=""
        >
        <gc-text
          variant="body-code-sm-bold"
          :text="name"
          color="text-white"
          />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
