
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

// console.log('fullConfig', fullConfig);
const colorProps = () => [
  '', //
  'text-white',
  'text-primary',
  'text-secondary',
  'text-transparent',
  'text-error',
  'danger',
  'text-muted',
  'text-blue-500',
  'text-red-500',
  'bg-gray-100',
  'bg-white',
  Object.keys(fullConfig.theme.colors.green).map(e => `text-green-${e}`),
  Object.keys(fullConfig.theme.colors.gray).map(e => `text-gray-${e}`),
  Object.keys(fullConfig.theme.colors.primary).map(e => `text-primary-${e}`),
  Object.keys(fullConfig.theme.colors.secondary).map(e => `text-secondary-${e}`),
].flat();


// console.log('colorProps', colorProps());

const alignProps = [
  'left', //
  'center',
  'right',
];

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
    },
    ruid: {
      type: String,
      required: false,
    },
    validationFlags: {
      type: Object,
      required: false,
      default: null,
    },
    validationRules: {
      type: [String, Object],
      required: false,
      default: null,
    },
    validationId: {
      type: String,
      required: false,
      default: null,
    },
    validationName: {
      type: String,
      required: false,
      default: null,
    },
    validationRef: {
      type: String,
      required: false,
      default: null,
    },
    validationInteractionMode: {
      type: String,
      required: false,
      default: 'eager',
    },
    heading: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: [String, Function, Array],
      required: false,
      default: null,
    },
    svg: {
      type: [String, Object],
      required: false,
      default: null,
    },
    svgHover: {
      type: [String, Object],
      required: false,
      default: null,
    },
    svgAlign: {
      type: String,
      required: false,
      default: null,
      validator: value => alignProps.includes(value),
    },
    svgColor: {
      type: String,
      required: false,
      default: 'text-gray-500',
      validator: value => colorProps().includes(value),
    },
    color: {
      type: String,
      required: false,
      default: 'text-gray-500',
      validator: value => colorProps().includes(value),
    },
    bgColor: {
      type: String,
      required: false,
      default: '',
      validator: value => colorProps().includes(value),
    },
    block: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    activeColor: {
      type: String,
      required: false,
      default: null,
    },
    passiveColor: {
      type: String,
      required: false,
      default: null,
    },
    hasPasswordToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    labelColor: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hasPlaceholder: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
      required: false,
    },
    autocomplete: {
      type: String,
      default: null,
      required: false,
    },
    autocorrect: {
      type: String,
      default: null,
      required: false,
    },
    autocapitalize: {
      type: String,
      default: null,
      required: false,
    },
    passwordrules: {
      type: String,
      default: null,
      required: false,
    },
    pattern: {
      type: String,
      default: null,
      required: false,
    },
    min: {
      type: String,
      default: null,
      required: false,
    },
    max: {
      type: String,
      default: null,
      required: false,
    },
    maxlength: {
      type: String,
      default: null,
      required: false,
    },
    minlength: {
      type: String,
      default: null,
      required: false,
    },
    step: {
      type: String,
      default: null,
      required: false,
    },
    padding: {
      type: String,
      default: 'p-6',
      required: false,
    },
    margin: {
      type: String,
      default: null,
      required: false,
    },
    src: {
      type: String,
      required: false,
      default: undefined,
    },
    filePath: {
      type: String,
      required: false,
      default: undefined,
    },
    image: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
  },
  methods: {
    colorProps,
  },
};
