<template>
  <div id="app">
    <gc-notification-toaster />

    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import gcNotificationToaster from '@/components/NotificationToaster.vue';

export default {
  components: {
    gcNotificationToaster,
  },
};
</script>
