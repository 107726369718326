<template>
  <div>
    <!-- {{ elapsed }} -->
    <!-- <br> -->
    <!-- {{ slide }} -->

    <div
      id="carousel"
      class="
        min-h-xs
        sm:min-h-xs
        lg:min-h-lg
        min-w-xs
        sm:min-w-xs
        lg:min-w-lg
        relative
        overflow-hidden
      "
      >
      <transition
        name="rotate2"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @after-leave="afterLeave"
        >
        <div
          :key="images[slide].image"
          class="max-w-lg z-10 absolute"
          >
          <gc-image
            :has-placeholder="true"
            :file-path="`collection/${images[slide].image}.png`"
            />
          <!-- @click.native="dismiss" -->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { getRandomInt } from '@/mixins/utils';

export default {
  data() {
    return {
      images: [
        {
          image: 'gentlemen-club-carousel-1',
          name: 'Gregor Lager',
        },
        {
          image: 'gentlemen-club-carousel-2',
          name: 'Yuudai Antonis',
        },
        {
          image: 'gentlemen-club-carousel-3',
          name: 'Heck Holmström',
        },
        {
          image: 'gentlemen-club-carousel-4',
          name: 'Sepp Negri',
        },
        {
          image: 'gentlemen-club-carousel-5',
          name: 'Walter Bergmann',
        },
        {
          image: 'gentlemen-club-carousel-6',
          name: 'Alfred Andriessen',
        },
        {
          image: 'gentlemen-club-carousel-7',
          name: 'Barnaba Lamon',
        },
        {
          image: 'gentlemen-club-carousel-8',
          name: 'Seto Isao',
        },
        {
          image: 'gentlemen-club-carousel-9',
          name: 'Arne Sangster',
        },
        {
          image: 'gentlemen-club-carousel-10',
          name: 'Liam Berg',
        },
        {
          image: 'gentlemen-club-carousel-11',
          name: 'Gideon Moto',
        },
        {
          image: 'gentlemen-club-carousel-12',
          name: 'Tino Merlo',
        },
        {
          image: 'gentlemen-club-carousel-13',
          name: 'Léon Kistler',
        },
        {
          image: 'gentlemen-club-carousel-14',
          name: 'Salvo Jardine',
        },
        {
          image: 'gentlemen-club-carousel-15',
          name: 'Yevgeni Peeters',
        },
        {
          image: 'gentlemen-club-carousel-16',
          name: 'Ryota Cucinotta',
        },
        {
          image: 'gentlemen-club-carousel-17',
          name: 'Kamil Baak',
        },
        {
          image: 'gentlemen-club-carousel-18',
          name: 'Klaus-Peter Picasso',
        },
        {
          image: 'gentlemen-club-carousel-19',
          name: 'Biaggio Vasilev',
        },
        {
          image: 'gentlemen-club-carousel-20',
          name: 'Vincente Šimon',
        },
        {
          image: 'gentlemen-club-carousel-21',
          name: 'Tendai Van Ophoven',
        },
        {
          image: 'gentlemen-club-carousel-22',
          name: 'Fabien Graner',
        },
        {
          image: 'gentlemen-club-carousel-23',
          name: 'Ichiro Hoffman',
        },
        {
          image: 'gentlemen-club-carousel-24',
          name: 'Raoul Flores',
        },
        {
          image: 'gentlemen-club-carousel-25',
          name: 'Uberto Graf',
        },
        {
          image: 'gentlemen-club-carousel-26',
          name: 'Primo Jordan',
        },
        {
          image: 'gentlemen-club-carousel-27',
          name: 'Timo Martelli',
        },
        {
          image: 'gentlemen-club-carousel-28',
          name: 'Christiaan Capitani',
        },
        {
          image: 'gentlemen-club-carousel-29',
          name: 'Ofra Denzel',
        },
        {
          image: 'gentlemen-club-carousel-30',
          name: 'Santo Henriksson',
        },
        {
          image: 'gentlemen-club-carousel-31',
          name: 'Gilbert Béringer',
        },
        {
          image: 'gentlemen-club-carousel-32',
          name: 'Giulio Sacco',
        },
        {
          image: 'gentlemen-club-carousel-33',
          name: 'Noé Estrada',
        },
        {
          image: 'gentlemen-club-carousel-34',
          name: 'Lau Terzi',
        },
        {
          image: 'gentlemen-club-carousel-35',
          name: 'Luka Strobel',
        },
      ],
      slide: 0,
      displayDuration: 4000,
      elapsedUntilPaused: 0,
      elapsed: 0,
      isDismissed: false,
      isRunning: false,
      rafId: null,
    };
  },
  computed: {},
  created() {
    // this.$log.info('Created LandingCarousel');
    this.getRandomSlide();
  },
  mounted() {
    this.kickTimer();
  },
  methods: {
    getRandomInt,

    getRandomSlide() {
      const newSlide = this.getRandomInt(Object.keys(this.images).length);

      if (newSlide === this.slide) {
        // this.$log.warn('was same');
        this.getRandomSlide();
      } else {
        // this.$log.warn('will set slide');
        this.slide = newSlide;
        this.$root.$emit('current-slide', this.images[this.slide]);
      }
    },

    kickTimer() {
      // this.$log.info('Starting kickTimer');
      this.isRunning = true;

      const time = {
        start: performance.now(),
      };

      const tick = (now) => {
        if (this.isDismissed) {
          this.$log.info('Dismissed');
          return;
        }

        if (!this.isRunning) {
          this.elapsedUntilPaused = Math.round(time.elapsed);
          this.$log.info(
            'Paused: elapsed time until now',
            this.elapsedUntilPaused,
          );
          return;
        }

        time.elapsed = now - time.start + this.elapsedUntilPaused;

        // this.$log.info('Timer:', Math.round(time.elapsed));

        this.elapsed = Math.round(time.elapsed);

        if (time.elapsed < this.displayDuration) {
          cancelAnimationFrame(this.rafId);
          this.rafId = requestAnimationFrame(tick);
          // this.$root.$emit('notificationState', this.notificationState);
        } else if (!this.isDismissed) {
          // this.$log.info('Will dismiss as result of timeout');
          this.dismiss();
        }
      };
      requestAnimationFrame(tick);
    },

    beforeEnter() {
      // this.$log.info('beforeEnter', this.slide);
      this.isDismissed = false;
      this.kickTimer();
    },

    afterEnter() {
      // this.$log.info('afterEnter', this.slide);
    },

    afterLeave() {
      // this.$log.info('afterLeave');
    },

    dismiss() {
      this.getRandomSlide();

      this.elapsedUntilPaused = 0;
      this.isDismissed = true;
      this.isRunning = false;
    },
  },
};
</script>

<style>
#carousel {
  z-index: 0;
}
#carousel > :first-child {
  z-index: 3;
}
</style>
