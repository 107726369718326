<template>
  <gc-io-observer
    id="partners"
    class="flex pb-12"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="flex flex-col items-center flex-grow"
      >
      <gc-heading
        tag="h2"
        color="text-white"
        class="font-black uppercase text-lg mb-12"
        >
        {{ $t('pages.partners.title') }}
      </gc-heading>

      <div
        class="flex flex-col lg:flex-row flex-grow items-center lg:max-w-7xl"
        >
        <a
          href="https://nftcalendar.io/event/gentlemen-club/"
          target="_blank"
          >
          <img
            src="/images/nft-calendar-transparent.png"
            alt="NFT Calendar"
            width="200"
            height="200"
            >
        </a>
      </div>
    </div>
  </gc-io-observer>
</template>

<script>
import { mapMutations } from 'vuex';
import gcIoObserver from '@/components/IoObserver.vue';

export default {
  components: {
    gcIoObserver,
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
  },
};
</script>

<style>
</style>
