import * as Sentry from '@sentry/vue';

async function handleResponse(response) {
  const text = await response.text();
  if (!response.ok) {
    const responseError = {
      type: 'Error',
      message: response.statusText || 'Something went wrong',
      response,
      code: response.status || '',
    };

    const endpoint = responseError?.response?.url;
    const status = responseError?.response?.status;
    const message = responseError?.message;

    const sentryEventMessage = `Fetch API: ${status} @ ${endpoint}`;

    const error = new Error(sentryEventMessage, { cause: responseError });

    Sentry.withScope((scope) => {
      scope.setTag('endpoint', endpoint);
      scope.setTag('message', message);
      scope.setTag('status', status);

      scope.setExtra('endpoint', endpoint);
      scope.setExtra('message', message);
      scope.setExtra('status', status);


      Sentry.captureException(error, 'error');
    });

    throw error;
  }

  try {
    const data = JSON.parse(text);

    return Promise.resolve(data);
  } catch (ex) {
    return Promise.resolve(text);
  }
}

export default {
  async get(path, headers) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
    };

    const response = await fetch(`${path}`, requestOptions);

    return handleResponse(response);
  },

  async post(path, payload, headers) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${path}`, requestOptions);

    return handleResponse(response);
  },

  async securePost(path, payload, headers) {
    return this.post(path, payload, {
      ...headers,
    });
  },

  async doSecureFileUpload(path, payload) {
    const requestOptions = {
      method: 'POST',
      headers: {
      },
      body: payload,
    };

    const response = await fetch(`${path}`, requestOptions);

    return handleResponse(response);
  },

  async delete(path, headers) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        ...headers,
      },
    };

    const response = await fetch(`${path}`, requestOptions);

    return handleResponse(response);
  },

  async put(path, payload, headers) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${path}`, requestOptions);

    return handleResponse(response);
  },

};
