import Vue from 'vue';
import smoothscroll from 'smoothscroll-polyfill';
import VueGtag from 'vue-gtag';
import { VueLogger, options } from '@/logger';

import sentryInit from '@/mixins/sentryInit';
import router from './router';
import store from './store';
import i18n from './i18n';

import 'tailwindcss/tailwind.css';
import '@/assets/style.css';

import App from './App.vue';
import '@/mixins/globalComponents';

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ANALYTICS },
  pageTrackerSkipSamePath: false,
  pageTrackerTemplate(to) {
    return {
      page_path: `${to.path}${to.hash}`,
    };
  },
}, router);

smoothscroll.polyfill();
Vue.use(VueLogger, options);

sentryInit(router);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
