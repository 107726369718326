<template>
  <div
    class="flex flex-col items-center flex-grow pt-8"
    >
    <gc-heading
      tag="h2"
      color="text-white"
      class="uppercase font-bold pb-2 sm:text-left text-center text-3xl sm:text-xl"
      >
      {{ $t('pages.landing.preMintingActionTitle') }}
    </gc-heading>

    <gc-countdown-timer
      v-if="true"
      class="py-4"
      />

    <gc-text
      variant="body-code-sm"
      color="text-white"
      class="text-center pb-6 max-w-md"
      >
      {{ $t('pages.landing.preMintingActionBody') }}
    </gc-text>

    <gc-svg-base
      class="animate-bounce"
      color-class="fill-current text-white"
      svg="icons/icon-bottom-arrow"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gcCountdownTimer from '@/components/CountdownTimer.vue';

export default {
  components: {
    gcCountdownTimer,
  },
  computed: {
    ...mapGetters({
      isConnected: 'blockchain/isConnected',
      isClaimingNFT: 'blockchain/isClaimingNFT',
    }),
  },
};
</script>

<style>

</style>
