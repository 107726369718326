<template>
  <div
    id="home"
    class="flex flex-col bg-gray-900"
    >
    <gc-header />

    <gc-mobile-nav />

    <gc-landing />

    <gc-collection />

    <gc-story />

    <gc-roadmap />

    <gc-faq />

    <gc-team />

    <gc-partners />

    <gc-footer />
  </div>
</template>

<script>
import gcHeader from '@/components/Header.vue';
import gcLanding from '@/views/Landing.vue';
import gcCollection from '@/views/Collection.vue';
import gcStory from '@/views/Story.vue';
import gcRoadmap from '@/views/Roadmap.vue';
import gcFaq from '@/views/Faq.vue';
import gcTeam from '@/views/Team.vue';
import gcPartners from '@/views/Partners.vue';
import gcFooter from '@/components/Footer.vue';
import gcMobileNav from '@/components/MobileNav.vue';

export default {
  components: {
    gcHeader,
    gcLanding,
    gcCollection,
    gcStory,
    gcRoadmap,
    gcFaq,
    gcTeam,
    gcPartners,
    gcFooter,
    gcMobileNav,
  },
  async created() {
    // this.$log.info('Created: Home');
  },
  async mounted() {
    // this.$log.info('Mounted: Home');
    await this.$store.dispatch('blockchain/initWeb3');
  },
};
</script>
