import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

// const isDev = process.env.VUE_APP_ENV === 'development';
const dsn = process.env.VUE_APP_SENTRY_DSN;

const sentryInit = router => Sentry.init({
  Vue,
  // dsn: isDev ? '' : dsn,
  dsn,
  integrations(integrations) {
    const browserTracing = new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'dev.thegentlemen.club', 'thegentlemen.club', /^\//],
    });

    integrations.push(browserTracing);
    /**
     * Since Sentry 6.8.0 Dedupe integration became default which prevents
     * duplicate looking like events from being dispatched
     * e.g. dispatching a static string multiple times
     * As we do not want this, filter out Dedupe integration.
     */
    return integrations.filter(integration => integration.name !== 'Dedupe');
  },
  tracingOptions: {
    trackComponents: true,
  },
  release: `gentlemen-club@${process.env.VERSION}`,
  environment: process.env.VUE_APP_ENV,
  sampleRate: 1.0,
  attachProps: true,
  logErrors: true,
  tracesSampleRate: 0.7,
  debug: false,
});

export default sentryInit;
