import ApiService from '@/services/ApiService';

const resource = '';

export default {
  async getConfig() {
    return ApiService.get(`${resource}/config/config.json`);
  },

  async getAbi() {
    return ApiService.get(`${resource}/config/abi.json`);
  },

  async getAccumulatedHashString() {
    return ApiService.get(`${resource}/config/accumulatedHashString.json`);
  },

  async getProvenance() {
    return ApiService.get(`${resource}/config/provenance.json`);
  },
};
