<template>
  <div
    class="flex flex-1 flex-col transition-set items-center sm:items-start"
    >
    <div class="mb-4">
      <gc-svg-base
        color-class="fill-current text-white"
        svg="icons/icon-gold-brick"
        />
    </div>

    <gc-heading
      tag="h1"
      color="text-white"
      class="uppercase font-bold pb-2 sm:text-left text-center text-5xl  sm:text-5xl"
      >
      {{ $t('pages.landing.preMintingTitle') }}
    </gc-heading>

    <gc-text
      variant="body-code-sm"
      color="text-white"
      class="sm:text-left text-center pb-6 whitespace-pre-line"
      >
      {{ $t('pages.landing.preMintingBody') }}
    </gc-text>

    <gc-countdown-timer
      v-if="false"
      class="pb-6"
      />
  </div>
</template>

<script>
import gcCountdownTimer from '@/components/CountdownTimer.vue';

export default {
  components: {
    gcCountdownTimer,
  },
  data() {
    return {
    };
  },
};
</script>

<style>

</style>
