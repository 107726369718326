<template>
  <gc-io-observer
    id="roadmap"
    class="min-h-screen flex py-32"
    @io-callback="ioHandler($event)"
    >
    <div
      v-if="true"
      class="flex flex-col items-center flex-grow px-8"
      >
      <gc-section-heading
        :pre-title="$t('pages.roadmap.pretitle')"
        :title="$t('pages.roadmap.title')"
        color="text-white"
        />

      <div class="pt-12 flex-grow flex flex-col justify-center">
        <div
          v-for="(line, key) in $t('pages.roadmap.list', {
            returnObjects: true,
          })"
          :key="key"
          class="flex text-white"
          >
          <div class="flex flex-col items-center pr-8">
            <gc-svg-base
              svg="icons/icon-cross"
              color-class="fill-current text-gray-500"
              />
            <div class="flex-grow py-4 flex">
              <div class="border-l-2 border-gray-600 border-dashed" />
            </div>
          </div>

          <div class="pb-12">
            <gc-heading
              tag="h2"
              color="text-white"
              class="font-sans font-black text-base pb-4 uppercase text-collection-sizzling-red"
              >
              {{ line.title }}
            </gc-heading>

            <gc-text
              variant="body-code-sm"
              color="text-white"
              class="max-w-2xl whitespace-pre-line tracking-tight"
              >
              {{ line.body }}
            </gc-text>
          </div>
        </div>
      </div>
    </div>
  </gc-io-observer>
</template>

<script>
import { mapMutations } from 'vuex';
import gcIoObserver from '@/components/IoObserver.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    gcIoObserver,
    gcSectionHeading,
  },
  methods: {
    ...mapMutations({
      setState: 'common/setState',
    }),
    ioHandler(event) {
      this.$store.dispatch('common/handleViewObserver', {
        event,
        vm: this,
      });
    },
  },
};
</script>

<style>
</style>
