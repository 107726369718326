<template>
  <div
    :class="getSectionStyle.background"
    class="
      min-h-screen

      flex flex-col
      justify-center
      items-center
      mx-auto
      px-4
    "
    >
    <gc-svg-base
      svg="gentlemen-club"
      class="w-44 sm:w-56"
      :color-class="`fill-current text-white transition-all duration-500 ease-out`"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getSectionStyle: 'common/getSectionStyle',
    }),
  },
};
</script>

<style>
</style>
