<template>
  <ul class="px-8">
    <li
      v-for="(item, i) in nav"
      :key="i"
      class="flex justify-center xl:inline-block px-4 uppercase pb-8 xl:pb-0"
      >
      <router-link
        v-slot="{ href, navigate, isActive }"
        :to="`/#${item.hash}`"
        class="group"
        custom
        >
        <a
          :href="href"
          @click="navigate"
          >
          <gc-text
            variant="body-sm-bold"
            :class="[
              isActive ? ' border-red-500' : 'border-transparent',
              textHoverColor,
            ]"
            class="
              border-b-2
              transition-all
              duration-500
              ease-out
              delay-100
              inline
              pb-2
            "
            :color="textColor"
            @click.native="$root.$emit('dismiss-notification'), $gtag.event(`navigation_${item.name}`)"
            >
            {{ item.name }}
          </gc-text>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      required: false,
      default: 'text-white',
    },
    textHoverColor: {
      type: String,
      required: false,
      default: 'group-hover:text-gray-900',
    },
  },
  data() {
    return {
      nav: [
        {
          name: 'collection',
          hash: 'collection',
        },
        {
          name: 'story',
          hash: 'story',
        },
        {
          name: 'roadmap',
          hash: 'roadmap',
        },
        {
          name: 'faq',
          hash: 'faq',
        },
        {
          name: 'team',
          hash: 'team',
        },
      ],
    };
  },
  methods: {
    handleNavigate(navigate) {
      navigate();
      this.$root.$emit('dismiss-notification');
    },
  },
};
</script>

<style>
</style>
