<template>
  <div
    id="footer"
    class="container flex flex-col justify-center w-72 sm:w-full items-center mx-auto px-4 py-24 bg-red-200 min-h-72"
    >
    <router-link to="/#landing">
      <gc-svg-base
        svg="gentlemen-club"
        class="w-44 sm:w-56"
        color-class="fill-current text-gray-800 opacity-10"
        />
    </router-link>

    <gc-navigation
      class="pt-12 sm:flex flex-col sm:flex-row text-center"
      text-color="text-gray-600"
      text-hover-color="group-hover:text-gray-900"
      />


    <div class="pt-12 flex">
      <a
        v-for="(item, i) in getSocials"
        :key="i"
        :href="item.href"
        target="_blank"
        >
        <gc-svg-base
          :color-class="`mx-4 fill-current text-gray-800 hover:text-gray-900
              transition-set delay-150`"
          :svg="`icons/${item.icon}`"
          />
      </a>
    </div>

    <div class="flex flex-col items-center">
      <gc-legal-navigation
        class="py-8 opacity-50"
        text-color="text-gray-600"
        text-hover-color="group-hover:text-gray-900"
        />
      <gc-text
        color="text-gray-900"
        variant="body-code-xs"
        class="text-center opacity-30"
        >
        {{ $t("pages.footer.copyright", { year: new Date().getFullYear(), version }) }}
      </gc-text>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import gcNavigation from '@/components/Navigation.vue';
import gcLegalNavigation from '@/components/LegalNavigation.vue';

export default {
  components: {
    gcNavigation,
    gcLegalNavigation,
  },
  computed: {
    ...mapState({
      version: st => st.common.version,
    }),
    ...mapGetters({
      getSectionStyle: 'common/getSectionStyle',
      getSocials: 'common/getSocials',
    }),
  },
};
</script>

<style>

</style>
