<template>
  <div
    class="page flex flex-col bg-gray-900"
    >
    <gc-header />
    <slot />
    <gc-footer />
  </div>
</template>

<script>
import gcHeader from '@/components/Header.vue';
import gcFooter from '@/components/Footer.vue';

export default {
  components: {
    gcHeader,
    gcFooter,
  },
};
</script>

<style>

</style>
