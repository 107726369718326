<template>
  <div class="flex flex-col items-center flex-grow">
    <!-- Supply -->
    <div class="flex py-6">
      <gc-text
        :text="`${blockchain.totalSupply === 0 ? '?' : blockchain.totalSupply}`"
        color="text-white"
        variant="body-code-sm-bold"
        class="tracking-wider"
        />
      <gc-text
        class="tracking-wider px-1"
        text="/"
        color="text-white"
        variant="body-code-sm-bold"
        />
      <gc-text
        :text="`${blockchain.config.MAX_SUPPLY}`"
        color="text-white"
        variant="body-code-sm-bold"
        class="tracking-wider"
        />
    </div>

    <!-- Mint Amount -->
    <div class="flex items-center">
      <gc-button
        text="-"
        variant="primary-slim-muted"
        :has-min-width="false"
        class="min-w-28"
        :disabled="isClaimingNFT"
        @click.native="mintAmount > 1 ? mintAmount-- : null"
        />
      <div class="px-8">
        <gc-text
          :text="`${mintAmount}`"
          color="text-white"
          variant="body-code-sm-bold"
          class="tracking-wider"
          />
      </div>
      <gc-button
        text="+"
        variant="primary-slim-muted"
        :has-min-width="false"
        class="min-w-28"
        :disabled="isClaimingNFT"
        @click.native="mintAmount < maxMintAmount ? mintAmount++ : null"
        />
    </div>

    <div class="py-4">
      <gc-text
        color="text-white"
        variant="body-code-sm-bold"
        class="tracking-wider px-8 text-center"
        >
        {{
          $t("pages.landing.cost", {
            NFTCost: Number(getNFTCost * mintAmount).toFixed(2),
          })
        }}
      </gc-text>
    </div>

    <gc-button
      :class="
        isConnected
          ? 'bg-white text-gray-900 hover:bg-gray-900 hover:text-white'
          : 'bg-gray-900 hover:bg-gray-600'
      "
      :text="
        blockchain.claimingNFT
          ? $t('commons.mintingInProgress')
          : $t('commons.mint')
      "
      :block="false"
      variant="plain"
      :disabled="isClaimingNFT || isMaxSupplyReached"
      @click.native="mintHandler"
      />
    <!-- <gc-button
      text="Push sample"
      :block="false"
      variant="red"
      @click.native="$store.dispatch('common/pushNotification')"
      /> -->
    <gc-text
      color="text-white"
      variant="body-code-xs"
      class="tracking-wider px-8 pt-4 text-center"
      >
      {{ $t("pages.landing.maxTransactionAllowlist", { maxMintAmount }) }}
    </gc-text>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      minMintAmount: 1,
      maxAmount: 3,
      mintAmount: 1,
    };
  },
  computed: {
    ...mapState({
      blockchain: state => state.blockchain,
    }),
    ...mapGetters({
      isConnected: 'blockchain/isConnected',
      isClaimingNFT: 'blockchain/isClaimingNFT',
      getNFTCost: 'blockchain/getNFTCost',
      getMerkleProof: 'blockchain/getMerkleProof',
      isAllowlistMintingFinished: 'blockchain/isAllowlistMintingFinished',
    }),
    maxMintAmount() {
      const { config, totalSupply } = this.blockchain;

      if (config.MAX_SUPPLY - totalSupply < this.maxAmount) {
        return this.blockchain.config.MAX_SUPPLY - this.blockchain.totalSupply;
      }

      return this.maxAmount;
    },
    isMaxSupplyReached() {
      return this.blockchain.totalSupply >= this.blockchain.config.MAX_SUPPLY;
    },
  },
  async created() {
    try {
      await this.$store.dispatch('blockchain/getSmartContract');
      await this.$store.dispatch('blockchain/getTotalSupply');
    } catch (error) {
      this.$log.info('Created: MintingNowActions', error);
    }

    if (this.isMaxSupplyReached) {
      this.setState(['currentPhase', 'post-minting']);
    }

    if (this.isAllowlistMintingFinished) {
      this.setState(['currentPhase', 'minting-now']);
    }
  },
  methods: {
    ...mapMutations({
      setState: 'blockchain/setState',
    }),
    mintHandler() {
      if (this.isConnected) {
        this.$gtag.event('btn_mint');
        return this.mint();
      }

      return this.$store.dispatch('common/pushNotification', {
        title: this.$t('feedbacks.walletTitle'),
        body: this.$t('feedbacks.connectToMint'),
        icon: 'icons/icon-metamask',
        duration: 5000,
        payload: {
          type: 'information',
        },
      });
    },
    async mint() {
      this.$log.warn('Will mint');

      try {
        const success = await this.$store.dispatch('blockchain/mint', {
          mintAmount: this.mintAmount,
          vm: this,
          mintType: 'allowlistMint',
        });
        this.$log.warn('mint success:', success);
      } catch (error) {
        this.$log.warn('mint', error.message);
      }
    },
  },
};
</script>

<style>
</style>
