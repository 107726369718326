<template>
  <div class="flex flex-col items-center flex-grow pt-16">
    <gc-heading
      tag="h2"
      color="text-white"
      class="
        uppercase
        font-bold
        pb-2
        sm:text-left
        text-center text-3xl
        sm:text-3xl
      "
      >
      {{ $t("pages.landing.postMintingActionTitle") }}
    </gc-heading>

    <gc-text
      variant="body-code-sm"
      color="text-white"
      class="text-center max-w-md"
      >
      {{ $t("pages.landing.postMintingActionBody") }}
    </gc-text>

    <gc-button
      tag="a"
      :href="blockchain.config.MARKETPLACE_LINK"
      :has-min-width="false"
      variant="primary"
      class="min-w-56 my-8 text-sm py-3"
      :block="false"
      svg="icons/icon-opensea"
      svg-align="right"
      svg-color="text-white"
      :text="$t('pages.story.primaryAction')"
      />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      blockchain: state => state.blockchain,
    }),
  },
};
</script>

<style>
</style>
