<template>
  <div class="min-h-screen flex flex-col items-center py-32">
    <router-link :to="{ name: 'home' }">
      <gc-svg-base
        svg="gentlemen-club"
        class="w-44 sm:w-56"
        color-class="`fill-current text-white transition-all duration-500 ease-out`"
        />
    </router-link>

    <div class="flex flex-col flex-grow justify-center items-center">
      <gc-section-heading
        :pre-title="$t('pages.pageNotFound.pretitle')"
        :title="$t('pages.pageNotFound.title')"
        color="text-white"
        class="pb-12"
        />
      <gc-button
        :text="$t('commons.back')"
        variant="red"
        :block="false"
        @click.native="$router.push({ name: 'home' }).catch(() => {})"
        />
    </div>
  </div>
</template>

<script>
// import gcHeader from '@/components/Header.vue';
import gcSectionHeading from '@/components/SectionHeading.vue';

export default {
  components: {
    // gcHeader,
    gcSectionHeading,
  },
};
</script>

<style>

</style>
