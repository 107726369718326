<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-10"
    >
    <transition
      v-if="showBackdrop"
      name="modal-back-drop"
      appear
      >
      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="min-h-screen bg-black opacity-50"
          @click="dismiss"
          />
      </div>
    </transition>

    <div
      v-if="false"
      class="bg-red-500"
      >
      isDismissed: {{ isDismissed }}
      <br>
      isRunning: {{ isRunning }}
      <br>
      elapsed: {{ elapsed }}
      <br>
      getTimelineWidth: {{ getTimelineWidth }}
    </div>

    <transition
      v-if="showContent"
      :name="transitionName"
      appear
      class=""
      @after-leave="afterLeave"
      >
      <div
        class="z-20 overflow-hidden p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        >
        <div
          v-if="isMobileNavigation"
          class="
            bg-white
            overflow-hidden
            flex flex-col
            w-full
            rounded-lg
            p-8
            xl:hidden
          "
          >
          <gc-navigation
            class="xl:flex"
            :text-color="getSectionStyle.mobileNavText"
            :text-hover-color="getSectionStyle.mobileNavTextHover"
            />
          <gc-button
            v-if="isConnected"
            :text="getShortAccountAddres"
            :block="true"
            :variant="getSectionStyle.button"
            :has-min-width="true"
            svg="icons/icon-checkmark"
            svg-align="right"
            svg-color="text-white"
            @click.native="
              $store
                .dispatch('blockchain/displayWallet')
                .then(() => $root.$emit('dismiss-notification')),
              $gtag.event('btn_connected')
            "
            />
          <gc-button
            v-else
            :text="$t('commons.connect')"
            :block="true"
            :variant="getSectionStyle.button"
            :has-min-width="true"
            @click.native="
              $root.$emit('dismiss-notification'),
              $store
                .dispatch('blockchain/connect', { vm: this })
                .catch(() => {}),
              $gtag.event('btn_connect')
            "
            />
        </div>

        <div
          v-else
          class="bg-white overflow-hidden rounded-lg sm:min-w-96 sm:max-w-xl"
          >
          <!-- Timeline -->
          <div
            v-if="displayDuration"
            class="relative"
            >
            <div class="h-1 text-xs flex bg-white">
              <div
                :style="`width: ${getTimelineWidth}%`"
                class="
                  shadow-none
                  flex flex-col
                  text-center
                  whitespace-nowrap
                  text-white
                  justify-center
                  bg-gray-900
                  transition-all
                  rounded-full
                "
                />
            </div>
          </div>

          <div class="p-10">
            <slot
              name="header"
              :close="dismiss"
              >
              <gc-heading
                v-if="heading"
                tag="h1"
                variant="h1"
                class="
                  border-b border-gray-200
                  pb-4
                  mb-8
                  font-bold
                  text-2xl text-center
                  sm:text-left
                "
                >
                {{ heading }}
              </gc-heading>
            </slot>

            <slot
              name="body"
              :close="dismiss"
              >
              <div class="flex flex-col sm:flex-row items-center">
                <div
                  v-if="isErrorMessage"
                  class="
                    bg-red-500
                    rounded-full
                    self-center
                    p-3
                    sm:mr-6
                    mb-6
                    sm:mb-0
                  "
                  >
                  <gc-svg-base
                    color-class="fill-current text-white"
                    svg="icons/icon-cross"
                    />
                </div>

                <div
                  v-if="isSuccessMessage"
                  class="
                    bg-green-500
                    rounded-full
                    self-center
                    p-3
                    sm:mr-6
                    mb-6
                    sm:mb-0
                  "
                  >
                  <gc-svg-base
                    color-class="fill-current text-white"
                    svg="icons/icon-checkmark"
                    class=""
                    />
                </div>

                <gc-svg-base
                  v-if="payload.icon"
                  :svg="payload.icon"
                  class="mb-6 sm:mb-0 sm:mr-6"
                  :color-class="`fill-current ${payload && payload.iconColor}`"
                  />

                <div class="flex flex-col flex-wrap items-start">
                  <gc-text
                    variant="body"
                    class="inline"
                    >
                    {{ body }}
                  </gc-text>

                  <div v-if="isReceiptMessage">
                    <gc-text
                      variant="body-bold"
                      class="break-all pt-3"
                      >
                      {{ $t("feedbacks.transactionHash") }}
                    </gc-text>

                    <gc-text
                      variant="body-code-xs"
                      class="break-all"
                      >
                      <a
                        :href="`${getScannerURL}tx/${payload.payload.receipt.transactionHash}`"
                        class="hover:underline"
                        target="_blank"
                        >
                        {{ payload.payload.receipt.transactionHash }}
                      </a>
                    </gc-text>
                  </div>

                  <div
                    v-if="isWalletMessage"
                    class="flex flex-col items-center sm:items-start"
                    >
                    <gc-text
                      variant="body-bold"
                      class="inline"
                      >
                      {{ $t("commons.address") }}
                    </gc-text>

                    <gc-text
                      variant="body-code-sm"
                      class="inline break-all pb-3"
                      >
                      {{ payload.payload.wallet.account }}
                    </gc-text>

                    <gc-text
                      variant="body-bold"
                      class="inline"
                      >
                      {{ $t("commons.balance") }}
                    </gc-text>
                    <gc-text
                      variant="body-code-sm"
                      class="inline"
                      >
                      {{ payload.payload.wallet.balance }}
                      {{ $t("commons.eth") }}
                    </gc-text>
                  </div>
                </div>
              </div>
            </slot>

            <div class="mt-8 border-t border-gray-200 pt-8">
              <slot
                name="footer"
                :close="dismiss"
                >
                <gc-button
                  variant="secondary"
                  :text="$t('commons.dismiss')"
                  @click.native="dismiss"
                  />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gcNavigation from '@/components/Navigation.vue';

export default {
  components: {
    gcNavigation,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    // displayDuration: {
    //   type: Number,
    //   required: false,
    //   default: 5000,
    // },
    transitionName: {
      type: String,
      default: 'slide-up',
      required: false,
    },
  },
  data() {
    return {
      showContent: true,
      showBackdrop: true,
      elapsedUntilPaused: 0,
      elapsed: 0,
      isDismissed: false,
      isRunning: false,
      rafId: null,
    };
  },
  computed: {
    ...mapGetters({
      getScannerURL: 'blockchain/getScannerURL',
      getSectionStyle: 'common/getSectionStyle',
      getCurrentPhase: 'blockchain/getCurrentPhase',
      getSocials: 'common/getSocials',
      isConnected: 'blockchain/isConnected',
      getShortAccountAddres: 'blockchain/getShortAccountAddres',
    }),
    body() {
      return this.payload.body;
    },
    heading() {
      return this.payload.title;
    },
    displayDuration() {
      return this.payload.duration;
    },
    getTimelineWidth() {
      return ((this.elapsed / this.displayDuration) * 100).toFixed(2);
    },
    isMobileNavigation() {
      return this.payload?.payload?.type === 'mobile-navigation';
    },
    isWalletMessage() {
      return this.payload?.payload?.type === 'wallet';
    },
    isReceiptMessage() {
      return this.payload?.payload?.type === 'receipt';
    },
    isErrorMessage() {
      return this.payload?.payload?.type === 'error';
    },
    isSuccessMessage() {
      return this.payload?.payload?.type === 'success';
    },
  },
  created() {
    const onEscape = (e) => {
      if (this.isOpen && e.keyCode === 27) {
        this.dismiss();
      }
    };

    document.addEventListener('keydown', onEscape);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape);
    });

    this.$root.$once('dismiss-notification', () => {
      // this.$log.info('close-all-modals received. Closing modal');
      this.dismiss();
    });

    if (this.displayDuration) {
      this.kickTimer();
    }
  },
  methods: {
    kickTimer() {
      this.$log.info('Starting kickTimer');
      this.isRunning = true;

      const time = {
        start: performance.now(),
      };

      const tick = (now) => {
        if (this.isDismissed) {
          this.$log.info('Dismissed');
          return;
        }

        if (!this.isRunning) {
          this.elapsedUntilPaused = Math.round(time.elapsed);
          this.$log.info(
            'Paused: elapsed time until now',
            this.elapsedUntilPaused,
          );
          return;
        }

        time.elapsed = now - time.start + this.elapsedUntilPaused;

        // this.$log.info('Timer:', Math.round(time.elapsed));

        this.elapsed = Math.round(time.elapsed);

        if (time.elapsed < this.displayDuration) {
          cancelAnimationFrame(this.rafId);
          this.rafId = requestAnimationFrame(tick);
          // this.$root.$emit('notificationState', this.notificationState);
        } else if (!this.isDismissed) {
          // this.$log.info('Will dismiss as result of timeout');
          this.dismiss();
        }
      };
      requestAnimationFrame(tick);
    },
    afterLeave() {
      // this.$log.info('afterLeave start');
      this.$emit('close');
      this.showContent = true;
      this.showBackdrop = true;
      this.isDismissed = false;
      this.isRunning = false;
      this.elapsedUntilPaused = 0;
    },
    dismiss() {
      // this.$log.info('Internal Close is called');
      this.showBackdrop = false;
      this.showContent = false;

      this.isDismissed = true;
      this.$root.$emit('notification-dismissed');
    },
  },
};
</script>

<style>
</style>
